<template>
  <div class="container">
    <div class="px-3">
      <div class="fixed-top w-100  py-2 d-flex" style="width: fit-content; background-color: white">
        <div class="container-fluid px-3 ">

          <div class="d-flex justify-content-between">
            <a href="https://satyaterrabhinneka.ac.id/profil/">
              <img src="@/assets/img/logo_navbar.png" alt="" style="max-height: 50px" />
            </a>
            <div class=" " v-if="isMobile == false">
              <a v-if="isMobile" target="_blank" href="mailto:pmb@satyaterrabhinneka.ac.id">
                <div class="d-flex align-items-center">
                  <i class="ri-mail-line fs-6 pe-2 text-danger"></i> pmb@satyaterrabhinneka.ac.id
                </div>
              </a>
              <a v-else target="_blank" class="d-flex align-items-center"
                href="https://mail.google.com/mail/u/0/?fs=1&to=pmb@satyaterrabhinneka.ac.id&tf=cm">

                <i class="ri-mail-line fs-6 pe-2 text-danger"></i> pmb@satyaterrabhinneka.ac.id

              </a>
              <div class="d-flex">
                <a target="_blank" class="d-flex align-items-center "
                  href="https://api.whatsapp.com/send?phone=6281337271791&text=Halo, admin Universitas Satya Terra Bhinneka! Saya ingin bertanya">
                  <i class="ri-whatsapp-line fs-6 pe-2 text-success"></i> Leony
                </a>
                <a target="_blank" class="d-flex align-items-center ps-2"
                  href="https://api.whatsapp.com/send?phone=6281262772997&text=Halo, admin Universitas Satya Terra Bhinneka! Saya ingin bertanya">
                  <i class="ri-whatsapp-line fs-6 pe-2 text-success "></i> Aldo
                </a>
                <a target="_blank" class="d-flex align-items-center ps-2"
                  href="https://api.whatsapp.com/send?phone=6281264255788&text=Halo, admin Universitas Satya Terra Bhinneka! Saya ingin bertanya">
                  <i class="ri-whatsapp-line fs-6 pe-2 text-success"></i> Nadia
                </a>
              </div>
            </div>
            <div v-if="isMobile">
              <button class="btn" @click="showBantuan();">
                <i class="ri-question-line fs-1 text-success"></i>
              </button>
            </div>

          </div>

        </div>
      </div>
    </div>
    <button ref="click_modal_bantuan" data-bs-toggle="modal" data-bs-target="#bantuan" hidden>
      click
    </button>
    <div class="modal fade" id="bantuan" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered ">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="label_data_karyawan">
              Bantuan <span class="text-capitalize"></span>
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body modal-body2">
            <a target="_blank" href="mailto:pmb@satyaterrabhinneka.ac.id" class="d-flex align-items-center ps-2">
              <i class="ri-mail-line fs-6 pe-2 text-danger"></i> pmb@satyaterrabhinneka.ac.id
            </a>

            <a target="_blank" class="d-flex align-items-center ps-2"
              href="https://api.whatsapp.com/send?phone=6281337271791&text=Halo, admin Universitas Satya Terra Bhinneka! Saya ingin bertanya">
              <i class="ri-whatsapp-line fs-6 pe-2 text-success"></i> (Leony) 081337271791
            </a>
            <a target="_blank" class="d-flex align-items-center ps-2"
              href="https://api.whatsapp.com/send?phone=6281262772997&text=Halo, admin Universitas Satya Terra Bhinneka! Saya ingin bertanya">
              <i class="ri-whatsapp-line fs-6 pe-2 text-success "></i> (Aldo) 081262772997
            </a>
            <a target="_blank" class="d-flex align-items-center ps-2"
              href="https://api.whatsapp.com/send?phone=6281264255788&text=Halo, admin Universitas Satya Terra Bhinneka! Saya ingin bertanya">
              <i class="ri-whatsapp-line fs-6 pe-2 text-success"></i> (Nadia) 081264255788
            </a>

          </div>
          <div class="modal-footer">
            <!-- <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              Batal
            </button>
            <button type="submit" class="btn btn-danger">Simpan</button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "HelloWorld",
  data() {
    return {
      isMobile: false,
    }
  },
  methods: {
    showBantuan() {
      this.$refs.click_modal_bantuan.click();
    }
  },
  mounted() {
    const userAgent = navigator.userAgent.toLowerCase();
    this.isMobile = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
a {
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  color: black;
}

a:hover {
  color: var(--jempolku);
}
</style>
