<template>
  <NavbarDefault />
  <section id="form_pendaftaran">
    <div class="mt-5 pt-5"></div>
    <div class="text-center mb-3 text-danger fw-bold fs-4">
      Formulir Pendaftaran Jalur Mandiri
    </div>
    <div class="container" v-on:contextmenu.prevent>
      <form @submit.prevent="recaptcha" @keydown.enter.prevent>
        <div v-show="this.page_number == 1">
          <div class="border border-2 rounded-top border-bottom-0 px-2 py-2 fw-bold fst-italic">
            Data Pribadi
          </div>
          <div class="w-100 h-100 border rounded-bottom border-2 px-3 py-3 ">
            <div class="row">
              <div class="col-12 col-md-3">
                <label for="nis" class="form-label">
                  Foto Diri/Selfie
                  <span class="text-danger">*</span>
                </label>
              </div>
              <div class="col-12 col-md-9 mb-3">
                <input type="file" name="foto_pas" class="form-control" id="foto_pas" ref="foto_pas"
                  accept="image/png, image/gif, image/jpeg" v-on:change="validateFileInput('foto_pas')"
                  @change="checkFileSize('foto_pas')" :class="{ 'is-invalid': !validateFileInput('foto_pas') }">
                <div id="input-file-feedback" class="invalid-feedback" v-if="!validateFileInput('foto_pas')">
                  {{ inputFileFeedback }}
                </div>
                <div class="text-secondary">
                  latar belakang polos, resolusi min 300x400, ukuran maks 1MB, format JPG atau PNG
                </div>
              </div>
              <div class="col-12 col-md-3">
                <label for="nis" class="form-label">
                  Nama Lengkap Sesuai Rapor atau Ijazah
                  <span class="text-danger">*</span>
                </label>
              </div>
              <div class="col-12 col-md-9 mb-3">
                <input type="text" name="nis" class="form-control" id="nis"
                  @input="handleInputNama($event, 'nama_lengkap')" v-model="currentRecord.nama_lengkap"
                  v-bind:class="{ 'is-invalid': !isInputTextValid('nama_lengkap') }" />
                <div id="input-text-feedback" class="invalid-feedback" v-if="!isInputTextValid('nama_lengkap')">{{
                  inputTextFeedback }}
                </div>
                <div class="text-secondary">
                  Nama yang diisikan adalah nama yang sesuai dokumen KTP/Kartu
                  Keluarga, Pengisian nama harus menggunakan huruf(tidak boleh ada
                  angka)
                </div>
              </div>
              <div class="col-12 col-md-3">
                <label for="nis" class="form-label">
                  Nama Depan
                  <span class="text-danger">*</span>
                </label>
              </div>
              <div class="col-12 col-md-9 mb-3">
                <input type="text" name="nis" class="form-control" id="nis"
                  @input="handleInputNama($event, 'nama_depan')" v-model="currentRecord.nama_depan"
                  v-bind:class="{ 'is-invalid': !isInputTextValid('nama_depan') }" />
                <div id="input-text-feedback" class="invalid-feedback" v-if="!isInputTextValid('nama_depan')">{{
                  inputTextFeedback }}
                </div>

              </div>
              <div class="col-12 col-md-3">
                <label for="nis" class="form-label">
                  Nama Tengah
                  <span class="text-danger">*</span>
                </label>
              </div>
              <div class="col-12 col-md-9 mb-3">
                <input type="text" name="nis" class="form-control" id="nis"
                  @input="handleInputNama($event, 'nama_tengah')" v-model="currentRecord.nama_tengah"
                  v-bind:class="{ 'is-invalid': !isInputTextValid('nama_tengah') }" />
                <div id="input-text-feedback" class="invalid-feedback" v-if="!isInputTextValid('nama_tengah')">{{
                  inputTextFeedback }}
                </div>

              </div>
              <div class="col-12 col-md-3">
                <label for="nis" class="form-label">
                  Nama Belakang
                  <span class="text-danger">*</span>
                </label>
              </div>
              <div class="col-12 col-md-9 mb-3">
                <input type="text" name="nis" class="form-control" id="nis"
                  @input="handleInputNama($event, 'nama_belakang')" v-model="currentRecord.nama_belakang"
                  v-bind:class="{ 'is-invalid': !isInputTextValid('nama_belakang') }" />
                <div id="input-text-feedback" class="invalid-feedback" v-if="!isInputTextValid('nama_belakang')">{{
                  inputTextFeedback }}
                </div>
              </div>

              <div class="col-12 col-md-3">
                <label for="nis" class="form-label">
                  Nomor Induk Siswa Nasional (NISN)
                  <span class="text-danger">*</span>
                </label>
              </div>
              <div class="col-12 col-md-9 mb-3">
                <input type="text" name="nis" class="form-control" id="nis" v-model="currentRecord.nisn_sekolah"
                  v-bind:class="{ 'is-invalid': !isInputTextValid('nisn_sekolah') }" maxlength="10" minlength="10"
                  @keypress="validateInput_number_only"
                  @change="validasi_ktp_nisn('nisn_sekolah', currentRecord.nisn_sekolah)" />
                <div id="input-text-feedback" class="invalid-feedback" v-if="!isInputTextValid('nisn_sekolah')">{{
                  inputTextFeedback_nisn }}
                </div>
              </div>
              <div class="col-12 col-md-3">
                <label for="nis" class="form-label">
                  Kewarganegaraan <span class="text-danger">*</span>
                </label>
              </div>
              <div class="col-12 col-md-9 mb-3">
                <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                  <input type="radio" class="btn-check" name="warga_negara" id="wni" autocomplete="off"
                    v-bind:checked="selectedOption === 'WNI'" v-on:change="updateSelectedOption('WNI')" />
                  <label class="btn btn-outline-secondary" for="wni"><i class="fa fa-window-close"
                      aria-hidden="true"></i>WNI</label>

                  <input type="radio" class="btn-check" name="warga_negara" id="wna" autocomplete="off"
                    v-bind:checked="selectedOption === 'WNA'" v-on:change="updateSelectedOption('WNA')" />
                  <label class="btn btn-outline-secondary" for="wna">WNA</label>
                </div>
              </div>
              <div class="col-12 col-md-3">
                <label for="nis" class="form-label">
                  {{ nikorpasspor }}<span class="text-danger">*</span>
                </label>
              </div>
              <div class="col-12 col-md-9 mb-3">
                <input type="text" name="nis" class="form-control" id="nis" v-model="currentRecord.no_ktp"
                  v-bind:class="{ 'is-invalid': !isInputTextValid('no_ktp') }" maxlength="16" minlength="16"
                  @keypress="validateInput_number_only_no_ktp"
                  @change="validasi_ktp_nisn('no_ktp', currentRecord.no_ktp)" />
                <div id="input-text-feedback" class="invalid-feedback" v-if="!isInputTextValid('no_ktp')">{{
                  inputTextFeedback_ktp }}
                </div>

              </div>

              <div class="col-12 col-md-3" v-if="selectedOption == 'WNI'">
                <label for="nis" class="form-label">
                  No Kartu Keluarga (KK)<span class="text-danger">*</span>
                </label>
              </div>
              <div class="col-12 col-md-9 mb-3" v-if="selectedOption == 'WNI'">
                <input type="text" name="no_kk" class="form-control" id="no_kk" v-model="currentRecord.no_kk"
                  v-bind:class="{ 'is-invalid': !isInputTextValid('no_kk') }" @keypress="validateInput_number_only"
                  maxlength="16" minlength="16" />
                <div id="input-text-feedback" class="invalid-feedback" v-if="!isInputTextValid('no_kk')">{{
                  inputTextFeedback_ktp }}
                </div>
              </div>


              <div class="col-12 col-md-3">
                <label for="nis" class="form-label">
                  Tempat Lahir <span class="text-danger">*</span>
                </label>
              </div>
              <div class="col-12 col-md-9 mb-3">
                <input type="text" name="nis" class="form-control" id="nis" v-model="currentRecord.tempat_lahir"
                  v-bind:class="{ 'is-invalid': !isInputTextValid('tempat_lahir') }"
                  @input="handleInputNama($event, 'tempat_lahir')" />
                <div id="input-text-feedback" class="invalid-feedback" v-if="!isInputTextValid('tempat_lahir')">{{
                  inputTextFeedback }}
                </div>
              </div>
              <div class="col-12 col-md-3">
                <label for="alamat" class="form-label">
                  Tanggal Lahir <span class="text-danger">*</span>
                </label>
              </div>
              <div class="col-12 col-md-9 mb-3">
                <input type="date" name="alamat" class="form-control" id="alamat"
                  v-bind:class="{ 'is-invalid': !isInputTextValid('tanggal_lahir') }"
                  v-model="currentRecord.tanggal_lahir" :max="tanggal_sekarang" :min="tanggal_max_lahir" />
                <div id="input-text-feedback" class="invalid-feedback" v-if="!isInputTextValid('tanggal_lahir')">{{
                  ValidasiTanggal }}
                </div>
              </div>

              <div class="col-12 col-md-3">
                <label for="nis" class="form-label">
                  Jenis Kelamin <span class="text-danger">*</span>
                </label>
              </div>
              <div class="col-12 col-md-9 mb-3">
                <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                  <input type="radio" class="btn-check" name="JenisKelamin" id="Pria" autocomplete="off"
                    v-bind:checked="selectedOption2 === 'Pria'" v-on:change="updateSelectedOption2('Pria')"
                    :disabled="isPriaDisabled" />
                  <label class="btn btn-outline-secondary" for="Pria"><i class="fa fa-window-close"
                      aria-hidden="true"></i>Pria</label>
                  <input type="radio" class="btn-check" name="JenisKelamin" id="Wanita" autocomplete="off"
                    v-bind:checked="selectedOption2 === 'Wanita'" v-on:change="updateSelectedOption2('Wanita')" />
                  <label class="btn btn-outline-secondary" for="Wanita">Wanita</label>
                </div>
              </div>

              <div class="col-12 col-md-3">
                <label for="alamat" class="form-label">
                  Alamat Tempat Tinggal <span class="text-danger">*</span>
                </label>
              </div>
              <div class="col-12 col-md-9 mb-3">
                <input type="text" name="alamat" class="form-control" id="alamat" v-model="currentRecord.alamat_siswa"
                  v-bind:class="{ 'is-invalid': !isInputTextValid('alamat_siswa') }" />
                <div id="input-text-feedback" class="invalid-feedback" v-if="!isInputTextValid('alamat_siswa')">{{
                  inputTextFeedback }}
                </div>
              </div>
              <div class="col-12 col-md-3">
                <label for="nis" class="form-label">
                  Provinsi <span class="text-danger">*</span>
                </label>
              </div>
              <div class="col-12 col-md-9 mb-3">
                <v-select v-model="provinsi_siswa" :options="provinsiList" placeholder="Pilih Provinsi"
                  :searchable="true" :class="{ 'is-invalid': !isInputTextValid('provinsi') }" ref="provinsi_siswa"
                  class="form-control custom-select"></v-select>
                <div class="invalid-feedback" v-if="!isInputTextValid('provinsi')">
                  {{ inputTextFeedback }}
                </div>
              </div>
              <div class="col-12 col-md-3">
                <label for="kota" class="form-label">
                  Kota/kabupaten<span class="text-danger">*</span>
                </label>
              </div>
              <div class="col-12 col-md-9 mb-3">
                <v-select v-model="kota_siswa" :options="kotaList" placeholder="Pilih Kota" :searchable="true"
                  :class="{ 'is-invalid': !isInputTextValid('kota') }" ref="kota_siswa"
                  class="form-control custom-select"></v-select>
                <div id="input-text-feedback" class="invalid-feedback" v-if="!isInputTextValid('kota')">{{
                  inputTextFeedback }}
                </div>
                <!-- <input list="kota" v-model="currentRecord.kota" class="form-control" @change="checkInputValidity('kota')"
                  v-bind:class="{ 'is-invalid': !isInputTextValid('kota') }"   />

                <datalist id="kota">
                  <option v-for="kota in kotaList" :value="kota.nama" :key="kota.nama"></option>
                </datalist> -->
              </div>
              <div class="col-12 col-md-3">
                <label for="kecamatan" class="form-label">
                  Kecamatan <span class="text-danger">*</span>
                </label>
              </div>
              <div class="col-12 col-md-9 mb-3">
                <v-select v-model="kecamatan_siswa" :options="kecamatanList" placeholder="Pilih Kecamatan"
                  :searchable="true" :class="{ 'is-invalid': !isInputTextValid('kecamatan') }" ref="kecamatan_siswa"
                  class="form-control custom-select"></v-select>
                <div id="input-text-feedback" class="invalid-feedback" v-if="!isInputTextValid('kecamatan')">{{
                  inputTextFeedback }}
                </div>
                <!-- <input list="kecamatan" v-model="currentRecord.kecamatan" class="form-control"
                  @change="checkInputValidity('kecamatan')"
                  v-bind:class="{ 'is-invalid': !isInputTextValid('kecamatan') }"   /> -->
                <!-- <datalist id="kecamatan">
                  <option v-for="kecamatan in kecamatanList" :value="kecamatan.nama" :key="kecamatan.nama"></option>
                </datalist> -->
              </div>
              <div class="col-12 col-md-3">
                <label for="kelurahan" class="form-label">
                  Kelurahan <span class="text-danger">*</span>
                </label>
              </div>
              <div class="col-12 col-md-9 mb-3">
                <v-select v-model="kelurahan_siswa" :options="kelurahanList" placeholder="Pilih kelurahan"
                  :searchable="true" :class="{ 'is-invalid': !isInputTextValid('kelurahan') }" ref="kelurahan_siswa"
                  class="form-control custom-select"></v-select>
                <div id="input-text-feedback" class="invalid-feedback" v-if="!isInputTextValid('kelurahan')">{{
                  inputTextFeedback }}
                </div>
                <!-- <input list="kelurahan" v-model="currentRecord.kelurahan" class="form-control"
                  @change="checkInputValidity('kelurahan')"
                  v-bind:class="{ 'is-invalid': !isInputTextValid('kelurahan') }"   /> -->
                <!-- <datalist id="kelurahan">
                  <option v-for="kelurahan in kelurahanList" :value="kelurahan.nama" :key="kelurahan.nama"></option>
                </datalist> -->
              </div>
              <div class="col-12 col-md-3">
                <label for="kodepos" class="form-label">
                  Kode Pos <span class="text-danger">*</span>
                </label>
              </div>
              <div class="col-12 col-md-9 mb-3">
                <input type="text" name="kodepos" class="form-control" @input="handleInputkodePos" id="kodepos"
                  v-model="currentRecord.kodepos" v-bind:class="{ 'is-invalid': !isInputTextValid('kodepos') }"
                  maxlength="5" />
                <div id="input-text-feedback" class="invalid-feedback" v-if="!isInputTextValid('kodepos')">{{
                  inputTextKodePos }}
                </div>
              </div>
              <div class="col-12 col-md-3">
                <label for="telepon_siswa" class="form-label">
                  No Telepon
                </label>
              </div>
              <div class="col-12 col-md-9 mb-3">
                <input type="tel" name="telepon_siswa" class="form-control" id="telepon_siswa"
                  v-model="currentRecord.telepon_siswa" @change="validateInput('telepon_siswa')"
                  @keypress="validateInput_number_only" />
              </div>
              <div class="col-12 col-md-3">
                <label for="no_handphone" class="form-label">
                  No Handphone <span class="text-danger">*</span>
                </label>
              </div>
              <div class="col-12 col-md-9 mb-3">
                <input type="text" name="no_handphone" class="form-control" id="no_handphone"
                  v-model="currentRecord.whatsapp" v-bind:class="{ 'is-invalid': !isInputTextValid('whatsapp') }"
                  @keypress="validateInput_number_only" @change="validateInput('whatsapp')" />
                <div id="input-text-feedback" class="invalid-feedback" v-if="!isInputTextValid('whatsapp')">{{
                  inputTextFeedback }}
                </div>
              </div>
              <div class="col-12 col-md-3">
                <label for="jumlah_bersaudara" class="form-label">
                  Jumlah Bersaudara <span class="text-danger">*</span>
                </label>
              </div>
              <div class="col-12 col-md-9 mb-3">
                <input type="number" name="jumlah_bersaudara" class="form-control" id="jumlah_bersaudara"
                  v-model="currentRecord.jumlah_bersaudara" @change="validasi_jumlah_saudara">
              </div>
              <div class="col-12 col-md-3">
                <label for="anak_ke" class="form-label">
                  Anak Ke- <span class="text-danger">*</span>
                </label>
              </div>
              <div class="col-12 col-md-9 mb-3">
                <input type="number" name="anak_ke" class="form-control" id="anak_ke" v-model="currentRecord.anak_ke"
                  @change='validasi_anak_ke_berapa' />
              </div>
              <div class="col-12 col-md-3">
                <label for="anak_ke" class="form-label">
                  Agama <span class="text-danger">*</span>
                </label>
              </div>
              <div class="col-12 col-md-9 mb-3">
                <select class="form-select" aria-label="Default select example" name="list" id="list"
                  v-model="currentRecord.agama" @change="checkInputValidity('agama')"
                  v-bind:class="{ 'is-invalid': !isInputTextValid('agama') }">
                  <option value="" selected disabled>Pilih
                    Agama </option>
                  <option v-for="list in agamaList" :key="list.nama" :value="list.nama">
                    {{ list.nama }}
                  </option>
                </select>
                <div id="input-text-feedback" class="invalid-feedback" v-if="!isInputTextValid('agama')">{{
                  inputTextFeedback }}
                </div>
                <!-- <input list="agama" v-model="currentRecord.agama" class="form-control"
                  @change="checkInputValidity('agama')" v-bind:class="{ 'is-invalid': !isInputTextValid('agama') }"
                    />
                <div id="input-text-feedback" class="invalid-feedback" v-if="!isInputTextValid('agama')">{{
                  inputTextFeedback }}
                </div>
                <datalist id="agama">
                  <option v-for="agama in agamaList" :value="agama.nama" :key="agama.nama"></option>
                </datalist> -->
              </div>

              <div class="col-12 col-md-3">
                <label for="anak_ke" class="form-label">
                  Hobi <span class="text-danger"></span>
                </label>
              </div>
              <div class="col-12 col-md-9 mb-3">
                <input type="text" name="hobi" class="form-control" id="hobi" v-model="currentRecord.hobi" />
              </div>

              <div class="col-12 col-md-3">
                <label for="nama_ayah" class="form-label">
                  Nama Ayah Kandung <span class="text-danger">*</span>
                </label>
              </div>
              <div class="col-12 col-md-9 mb-3">
                <input type="text" name="nama_ayah" class="form-control" id="nama_ayah"
                  v-model="currentRecord.nama_ayah" v-bind:class="{ 'is-invalid': !isInputTextValid('nama_ayah') }"
                  @input="handleInputNama($event, 'nama_ayah')" />
                <div id="input-text-feedback" class="invalid-feedback" v-if="!isInputTextValid('nama_ayah')">{{
                  inputTextFeedback }}
                </div>
              </div>
              <div class="col-12 col-md-3">
                <label for="nik_ayah" class="form-label">
                  NIK Ayah<span class="text-danger">*</span>
                </label>
              </div>
              <div class="col-12 col-md-9 mb-3">
                <input type="text" name="nik_ayah" class="form-control" id="nik_ayah" v-model="currentRecord.nik_ayah"
                  v-bind:class="{ 'is-invalid': !isInputTextValid('nik_ayah') }" maxlength="16" minlength="16"
                  @keypress="validateInput_number_only" />
                <div id="input-text-feedback" class="invalid-feedback" v-if="!isInputTextValid('nik_ayah')">{{
                  inputTextFeedback_nik_ayah }}
                </div>
              </div>
              <div class="col-12 col-md-3">
                <label for="no_hp_ayah" class="form-label">
                  No Hp Ayah Kandung <span class="text-danger">*</span>
                </label>
              </div>
              <div class="col-12 col-md-9 mb-3">
                <input type="text" name="no_hp_ayah" class="form-control" id="no_hp_ayah"
                  v-model="currentRecord.no_hp_ayah" v-bind:class="{ 'is-invalid': !isInputTextValid('no_hp_ayah') }"
                  @keypress="validateInput_number_only" />
                <div id="input-text-feedback" class="invalid-feedback" v-if="!isInputTextValid('no_hp_ayah')">{{
                  inputTextFeedback }}
                </div>
              </div>

              <div class="col-12 col-md-3 ">
                <label for="pekerjaan_ayah" class="form-label">
                  Pekerjaan Ayah <span class="text-danger">*</span>
                </label>
              </div>
              <div class="col-12 col-md-9 mb-3" v-if="isLainnyaPekerjaanAyah == false">
                <select class="form-select" aria-label="Default select example" name="list" id="list"
                  v-model="currentRecord.pekerjaan_ayah" @change="checkInputValidity('pekerjaan_ayah')"
                  v-bind:class="{ 'is-invalid': !isInputTextValid('pekerjaan_ayah') }">
                  <option value="" selected disabled>Pilih
                    Pekerjaan </option>
                  <option v-for="list in pekerjaanList" :key="list.nama" :value="list.nama">
                    {{ list.nama }}
                  </option>
                </select>
                <div id="input-text-feedback" class="invalid-feedback" v-if="!isInputTextValid('pekerjaan_ayah')">{{
                  inputTextFeedback }}
                </div>
                <!-- <input list="pekerjaan_ibu" v-model="currentRecord.pekerjaan_ibu" class="form-control"
                  @change="checkInputValidity('pekerjaan_ibu')"
                  v-bind:class="{ 'is-invalid': !isInputTextValid('pekerjaan_ibu') }"   />
                <div id="input-text-feedback" class="invalid-feedback" v-if="!isInputTextValid('pekerjaan_ibu')">{{
                  inputTextFeedback }}
                </div>
                <datalist id="pekerjaan_ibu">
                  <option v-for="data in pekerjaanList" :value="data.nama" :key="data.nama"></option>
                </datalist> -->
              </div>
              <div class="col-12 col-md-3 mb-2" v-if="isLainnyaPekerjaanAyah == true">
                <select class="form-select" aria-label="Default select example" name="list" id="list"
                  v-model="currentRecord.pekerjaan_ayah" @change="checkInputValidity('pekerjaan_ayah')"
                  v-bind:class="{ 'is-invalid': !isInputTextValid('pekerjaan_ayah') }">
                  <option value="" selected disabled>Pilih
                    Pekerjaan 2</option>
                  <option v-for="list in pekerjaanList" :key="list.nama" :value="list.nama">
                    {{ list.nama }}
                  </option>
                </select>
                <div id="input-text-feedback" class="invalid-feedback" v-if="!isInputTextValid('pekerjaan_ayah')">{{
                  inputTextFeedback }}
                </div>
                <!-- <input list="pekerjaan_ayah" v-model="currentRecord.pekerjaan_ayah" class="form-control"
                  @change="checkInputValidity('pekerjaan_ayah')"
                  v-bind:class="{ 'is-invalid': !isInputTextValid('pekerjaan_ayah') }"   />
                <div id="input-text-feedback" class="invalid-feedback" v-if="!isInputTextValid('pekerjaan_ayah')">{{
                  inputTextFeedback }}
                </div>
                <datalist id="pekerjaan_ayah">
                  <option v-for="data in pekerjaanList" :value="data.nama" :key="data.nama"></option>
                </datalist> -->
              </div>
              <div class="col-12 col-md-6 mb-3" v-if="isLainnyaPekerjaanAyah == true">
                <input type="text" name="pekerjaan_ayah_input" class="form-control" id="pekerjaan_ayah_input"
                  @input="handleInputNama2($event, 'isiLainnyaPekerjaanAyah')" v-model="isiLainnyaPekerjaanAyah" />
              </div>
              <div class="col-12 col-md-3">
                <label for="pendidikan_terakhir_ayah" class="form-label">
                  Pendidikan terakhir Ayah <span class="text-danger">*</span>
                </label>
              </div>
              <div class="col-12 col-md-9 mb-3">
                <select class="form-select" aria-label="Default select example" name="list" id="list"
                  v-model="currentRecord.pendidikan_terakhir_ayah"
                  @change="checkInputValidity('pendidikan_terakhir_ayah')"
                  v-bind:class="{ 'is-invalid': !isInputTextValid('pendidikan_terakhir_ayah') }">
                  <option value="" selected disabled>Pilih
                    Pendidikan terakhir </option>
                  <option v-for="list in pendidikanTerakhirList" :key="list.nama" :value="list.nama">
                    {{ list.nama }}
                  </option>
                </select>
                <div id="input-text-feedback" class="invalid-feedback"
                  v-if="!isInputTextValid('pendidikan_terakhir_ayah')">{{
                    inputTextFeedback }}
                </div>
                <!-- <input list="pendidikanTerakhirAyah" v-model="currentRecord.pendidikan_terakhir_ayah" class="form-control"
                  @change="checkInputValidity('pendidikan_terakhir_ayah')"
                  v-bind:class="{ 'is-invalid': !isInputTextValid('pendidikan_terakhir_ayah') }"   />
                <div id="input-text-feedback" class="invalid-feedback"
                  v-if="!isInputTextValid('pendidikan_terakhir_ayah')">{{
                    inputTextFeedback }}
                </div>
                <datalist id="pendidikanTerakhirAyah">
                  <option v-for="data in pendidikanTerakhirList" :value="data.nama" :key="data.nama"></option>
                </datalist> -->
              </div>
              <div class="col-12 col-md-3">
                <label for="penghasilan_ayah" class="form-label">
                  Pendapatan / Bulan Ayah <span class="text-danger">*</span>
                </label>
              </div>
              <div class="col-12 col-md-9 mb-3 position-relative">
                <input type="text" name="penghasilan_ayah" class="form-control " id="penghasilan_ayah"
                  @keypress="validateInput_number_only" :value="formattedPenghasilanAyah"
                  @input="updatePenghasilanAyah($event.target.value)" />
                <div class="position-absolute top-0 end-0 h-100">
                  <div class="d-flex align-items-center h-100 pe-4 me-1">
                    /Bulan
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-3">
                <label for="nama_ibu_kandung" class="form-label">
                  Nama Ibu Kandung <span class="text-danger">*</span>
                </label>
              </div>
              <div class="col-12 col-md-9 mb-3">
                <input type="text" name="nama_ibu_kandung" class="form-control" id="nama_ibu_kandung"
                  v-model="currentRecord.nama_ibu_kandung"
                  v-bind:class="{ 'is-invalid': !isInputTextValid('nama_ibu_kandung') }"
                  @input="handleInputNama($event, 'nama_ibu_kandung')" />
                <div id="input-text-feedback" class="invalid-feedback" v-if="!isInputTextValid('nama_ibu_kandung')">{{
                  inputTextFeedback }}
                </div>
                <div class="text-secondary">Nama Ibu sesuai Kartu Keluarga</div>
              </div>
              <div class="col-12 col-md-3">
                <label for="nik_ibu" class="form-label">
                  NIK Ibu<span class="text-danger">*</span>
                </label>
              </div>
              <div class="col-12 col-md-9 mb-3">
                <input type="text" name="nik_ibu" class="form-control" id="nik_ibu" v-model="currentRecord.nik_ibu"
                  v-bind:class="{ 'is-invalid': !isInputTextValid('nik_ibu') }" maxlength="16" minlength="16"
                  @keypress="validateInput_number_only" />
                <div id="input-text-feedback" class="invalid-feedback" v-if="!isInputTextValid('nik_ibu')">{{
                  inputTextFeedback_nik_ibu }}
                </div>
              </div>

              <div class="col-12 col-md-3">
                <label for="no_hp_ibu" class="form-label">
                  No Hp Ibu Kandung <span class="text-danger">*</span>
                </label>
              </div>
              <div class="col-12 col-md-9 mb-3">
                <input type="text" name="no_hp_ibu" class="form-control" id="no_hp_ibu"
                  v-model="currentRecord.no_hp_ibu" v-bind:class="{ 'is-invalid': !isInputTextValid('no_hp_ibu') }"
                  @keypress="validateInput_number_only" />
                <div id="input-text-feedback" class="invalid-feedback" v-if="!isInputTextValid('no_hp_ibu')">{{
                  inputTextFeedback }}
                </div>
              </div>

              <div class="col-12 col-md-3">
                <label for="pekerjaan_ibu_kandung" class="form-label">
                  Pekerjaan Ibu <span class="text-danger">*</span>
                </label>
              </div>
              <div class="col-12 col-md-9 mb-3" v-if="isLainnyaPekerjaanIbu == false">
                <select class="form-select" aria-label="Default select example" name="list" id="list"
                  v-model="currentRecord.pekerjaan_ibu" @change="checkInputValidity('pekerjaan_ibu')"
                  v-bind:class="{ 'is-invalid': !isInputTextValid('pekerjaan_ibu') }">
                  <option value="" selected disabled>Pilih
                    Pekerjaan </option>
                  <option v-for="list in pekerjaanList" :key="list.nama" :value="list.nama">
                    {{ list.nama }}
                  </option>
                </select>
                <div id="input-text-feedback" class="invalid-feedback" v-if="!isInputTextValid('pekerjaan_ibu')">{{
                  inputTextFeedback }}
                </div>
                <!-- <input list="pekerjaan_ibu" v-model="currentRecord.pekerjaan_ibu" class="form-control"
                  @change="checkInputValidity('pekerjaan_ibu')"
                  v-bind:class="{ 'is-invalid': !isInputTextValid('pekerjaan_ibu') }"   />
                <div id="input-text-feedback" class="invalid-feedback" v-if="!isInputTextValid('pekerjaan_ibu')">{{
                  inputTextFeedback }}
                </div>
                <datalist id="pekerjaan_ibu">
                  <option v-for="data in pekerjaanList" :value="data.nama" :key="data.nama"></option>
                </datalist> -->
              </div>
              <div class="col-12 col-md-3 mb-2" v-if="isLainnyaPekerjaanIbu == true">
                <select class="form-select" aria-label="Default select example" name="list" id="list"
                  v-model="currentRecord.pekerjaan_ibu_lainnya" @change="checkInputValidity('pekerjaan_ibu')"
                  v-bind:class="{ 'is-invalid': !isInputTextValid('pekerjaan_ibu') }">
                  <option value="" selected disabled>Pilih
                    Pekerjaan </option>
                  <option v-for="list in pekerjaanList" :key="list.nama" :value="list.nama">
                    {{ list.nama }}
                  </option>
                </select>
                <div id="input-text-feedback" class="invalid-feedback" v-if="!isInputTextValid('pekerjaan_ibu')">{{
                  inputTextFeedback }}
                </div>
                <!-- <input list="pekerjaan_ayah" v-model="currentRecord.pekerjaan_ayah" class="form-control"
                  @change="checkInputValidity('pekerjaan_ayah')"
                  v-bind:class="{ 'is-invalid': !isInputTextValid('pekerjaan_ayah') }"   />
                <div id="input-text-feedback" class="invalid-feedback" v-if="!isInputTextValid('pekerjaan_ayah')">{{
                  inputTextFeedback }}
                </div>
                <datalist id="pekerjaan_ayah">
                  <option v-for="data in pekerjaanList" :value="data.nama" :key="data.nama"></option>
                </datalist> -->
              </div>
              <div class="col-12 col-md-6 mb-3" v-if="isLainnyaPekerjaanIbu == true">
                <input type="text" name="pekerjaan_ibu_input" class="form-control" id="pekerjaan_ibu_input"
                  v-model="isiLainnyaPekerjaanIbu" @input="handleInputNama2($event, 'isiLainnyaPekerjaanIbu')" />
              </div>
              <div class="col-12 col-md-3">
                <label for="pendidikan_terakhir_ibu" class="form-label">
                  Pendidikan terakhir Ibu <span class="text-danger">*</span>
                </label>
              </div>
              <div class="col-12 col-md-9 mb-3">
                <select class="form-select" aria-label="Default select example" name="list" id="list"
                  v-model="currentRecord.pendidikan_terakhir_ibu"
                  @change="checkInputValidity('pendidikan_terakhir_ibu')"
                  v-bind:class="{ 'is-invalid': !isInputTextValid('pendidikan_terakhir_ibu') }">
                  <option value="" selected disabled>Pilih
                    Pendidikan terakhir </option>
                  <option v-for="list in pendidikanTerakhirList" :key="list.nama" :value="list.nama">
                    {{ list.nama }}
                  </option>
                </select>
                <div id="input-text-feedback" class="invalid-feedback"
                  v-if="!isInputTextValid('pendidikan_terakhir_ibu')">{{
                    inputTextFeedback }}
                </div>
                <!-- <input list="pendidikanTerakhirIbu" v-model="currentRecord.pendidikan_terakhir_ibu" class="form-control"
                  @change="checkInputValidity('pendidikan_terakhir_ibu')"
                  v-bind:class="{ 'is-invalid': !isInputTextValid('pendidikan_terakhir_ibu') }"   />
                <div id="input-text-feedback" class="invalid-feedback"
                  v-if="!isInputTextValid('pendidikan_terakhir_ibu')">{{
                    inputTextFeedback }}
                </div>
                <datalist id="pendidikanTerakhirIbu">
                  <option v-for="data in pendidikanTerakhirList" :value="data.nama" :key="data.nama"></option>
                </datalist> -->
              </div>
              <div class="col-12 col-md-3">
                <label for="penghasilan_ibu" class="form-label">
                  Pendapatan / Bulan Ibu <span class="text-danger">*</span>
                </label>
              </div>
              <div class="col-12 col-md-9 mb-3 position-relative">
                <input type="text" name="penghasilan_ibu" class="form-control " id="penghasilan_ibu"
                  @keypress="validateInput_number_only" :value="formattedPenghasilanIbu"
                  @input="updatePenghasilanIbu($event.target.value)">
                <div class="position-absolute top-0 end-0 h-100">
                  <div class="d-flex align-items-center h-100 pe-4 me-1">
                    /Bulan
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-3">
                <label for="penghasilan_ibu" class="form-label">
                  Alamat Orang tua <span class="text-danger">*</span>
                </label>
              </div>
              <div class="col-12 col-md-9 mb-3">
                <input type="text" name="alamat_ortu" class="form-control" id="alamat_ortu"
                  v-model="currentRecord.alamat_ortu"
                  v-bind:class="{ 'is-invalid': !isInputTextValid('alamat_ortu') }" />
                <div id="input-text-feedback" class="invalid-feedback" v-if="!isInputTextValid('alamat_ortu')">{{
                  inputTextFeedback }}
                </div>
              </div>
              <div class="col-12 col-md-3">
                <label for="provinsi_ortu" class="form-label">
                  Provinsi Orang tua <span class="text-danger">*</span>
                </label>
              </div>
              <div class="col-12 col-md-9 mb-3">
                <v-select v-model="provinsi_ortu" :options="provinsiList" placeholder="Pilih Provinsi"
                  :searchable="true" :class="{ 'is-invalid': !isInputTextValid('provinsi_ortu') }" ref="provinsi_ortu"
                  class="form-control custom-select"></v-select>
                <div id="input-text-feedback" class="invalid-feedback" v-if="!isInputTextValid('provinsi_ortu')">{{
                  inputTextFeedback }}
                </div>
                <!-- <input list="provinsi_ortu" v-model="currentRecord.provinsi_ortu" class="form-control"
                  @change="checkInputValidity('provinsi_ortu')"
                  v-bind:class="{ 'is-invalid': !isInputTextValid('provinsi_ortu') }"   />
                <datalist id="provinsi_ortu">
                  <option v-for="provinsi in provinsiList_ortu" :value="provinsi.nama" :key="provinsi.nama"></option>
                </datalist> -->
              </div>
              <div class="col-12 col-md-3">
                <label for="kota_ortu" class="form-label">
                  Kota/Kabupaten Orang tua <span class="text-danger">*</span>
                </label>
              </div>
              <div class="col-12 col-md-9 mb-3">
                <v-select v-model="kota_ortu" :options="kotaList_ortu" placeholder="Pilih Kota" :searchable="true"
                  :class="{ 'is-invalid': !isInputTextValid('kota_ortu') }" ref="kota_ortu"
                  class="form-control custom-select"></v-select>
                <div id="input-text-feedback" class="invalid-feedback" v-if="!isInputTextValid('kota_ortu')">{{
                  inputTextFeedback }}
                </div>
                <!-- <input list="kota_ortu" v-model="currentRecord.kota_ortu" class="form-control"
                  @change="checkInputValidity('kota_ortu')"
                  v-bind:class="{ 'is-invalid': !isInputTextValid('kota_ortu') }"   />
               
                <datalist id="kota_ortu">
                  <option v-for="kota in kotaList_ortu" :value="kota.nama" :key="kota.nama"></option>
                </datalist> -->
              </div>
              <div class="col-12 col-md-3">
                <label for="kecamatan_ortu" class="form-label">
                  Kecamatan Orang tua <span class="text-danger">*</span>
                </label>
              </div>
              <div class="col-12 col-md-9 mb-3">
                <v-select v-model="kecamatan_ortu" :options="kecamatanList_ortu" placeholder="Pilih Kecamatan"
                  :searchable="true" :class="{ 'is-invalid': !isInputTextValid('kecamatan_ortu') }" ref="kecamatan_ortu"
                  class="form-control custom-select"></v-select>
                <div id="input-text-feedback" class="invalid-feedback" v-if="!isInputTextValid('kecamatan_ortu')">{{
                  inputTextFeedback }}
                </div>
                <!-- <input list="kecamatan_ortu" v-model="currentRecord.kecamatan_ortu" class="form-control"
                  @change="checkInputValidity('kecamatan_ortu')"
                  v-bind:class="{ 'is-invalid': !isInputTextValid('kecamatan_ortu') }"   />
                <datalist id="kecamatan_ortu">
                  <option v-for="kecamatan in kecamatanList_ortu" :value="kecamatan.nama" :key="kecamatan.nama"></option>
                </datalist> -->
              </div>
              <div class="col-12 col-md-3">
                <label for="kelurahan_ortu" class="form-label">
                  Kelurahan Orang tua <span class="text-danger">*</span>
                </label>
              </div>
              <div class="col-12 col-md-9 mb-3">
                <v-select v-model="kelurahan_ortu" :options="kelurahanList_ortu" placeholder="Pilih Kelurahan"
                  :searchable="true" :class="{ 'is-invalid': !isInputTextValid('kelurahan_ortu') }" ref="kelurahan_ortu"
                  class="form-control custom-select"></v-select>
                <div id="input-text-feedback" class="invalid-feedback" v-if="!isInputTextValid('kelurahan_ortu')">{{
                  inputTextFeedback }}
                </div>
                <!-- <input list="kelurahan_ortu" v-model="currentRecord.kelurahan_ortu" class="form-control"
                  @change="checkInputValidity('kelurahan_ortu')"
                  v-bind:class="{ 'is-invalid': !isInputTextValid('kelurahan_ortu') }"   />
                <datalist id="kelurahan_ortu">
                  <option v-for="kelurahan in kelurahanList_ortu" :value="kelurahan.nama" :key="kelurahan.nama"></option>
                </datalist> -->
              </div>
              <div class="col-12 col-md-3">
                <label for="kodepos_ortu" class="form-label">
                  Kode Pos Orang Tua <span class="text-danger">*</span>
                </label>
              </div>
              <div class="col-12 col-md-9 mb-3">
                <input type="text" name="kodepos_ortu" class="form-control" id="kodepos_ortu"
                  @input="handleInputkodePosOrtu" v-model="currentRecord.kodepos_ortu"
                  v-bind:class="{ 'is-invalid': !isInputTextValid('kodepos_ortu') }" maxlength="5" />
                <div id="input-text-feedback" class="invalid-feedback" v-if="!isInputTextValid('kodepos_ortu')">{{
                  inputTextKodePos }}
                </div>
              </div>
              <div class="col-12 col-md-3">
                <label for="kodepos" class="form-label">
                  Nama Marketing <span class="text-danger">*</span>
                </label>
              </div>
              <div class="col-12 col-md-9 mb-3">
                <v-select v-model="currentRecord.admin" :options="adminList" placeholder="Pilih Marketing"
                  :searchable="true" :class="{ 'is-invalid': !isInputTextValid('admin') }" ref="admin"
                  class="form-control custom-select"></v-select>
                <div id="input-text-feedback" class="invalid-feedback" v-if="!isInputTextValid('admin')">{{
                  inputTextFeedback }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-show="this.page_number == 2">
          <div class="border border-2 rounded-top border-bottom-0 px-2 py-2 fw-bold fst-italic">
            Sekolah Asal
          </div>
          <div class="w-100 h-100 border rounded-bottom border-2 px-3 py-3 mb-5">
            <div class="row">
              <div class="col-12 col-md-3">
                <label for="nama_sekolah" class="form-label">
                  Nama Sekolah<span class="text-danger">*</span>
                </label>
              </div>
              <div class="col-12 col-md-9 mb-3">
                <input type="text" name="nama_sekolah" class="form-control" id="nama_sekolah"
                  v-model="currentRecord.nama_sekolah"
                  v-bind:class="{ 'is-invalid': !isInputTextValid('nama_sekolah', 2) }" />
                <div id="input-text-feedback" class="invalid-feedback" v-if="!isInputTextValid('nama_sekolah', 2)">{{
                  inputTextFeedback }}
                </div>
              </div>
              <div class="col-12 col-md-3">
                <label for="provinsi_sekolah" class="form-label">
                  Provinsi Sekolah <span class="text-danger">*</span>
                </label>
              </div>
              <div class="col-12 col-md-9 mb-3">
                <v-select v-model="provinsi_sekolah" :options="provinsiList_sekolah" placeholder="Pilih Provinsi"
                  :searchable="true" :class="{ 'is-invalid': !isInputTextValid('provinsi_sekolah', 2) }"
                  ref="provinsi_sekolah" class="form-control custom-select"></v-select>
                <div id="input-text-feedback" class="invalid-feedback" v-if="!isInputTextValid('provinsi_sekolah', 2)">
                  {{
                    inputTextFeedback }}
                </div>
                <!-- <input list="provinsi_sekolah" v-model="currentRecord.provinsi_sekolah" class="form-control"
                  @change="checkInputValidity('provinsi_sekolah', 2)"
                  v-bind:class="{ 'is-invalid': !isInputTextValid('provinsi_sekolah', 2) }"   />
                
                <datalist id="provinsi_sekolah">
                  <option v-for="provinsi in provinsiList_sekolah" :value="provinsi.nama" :key="provinsi.nama"></option>
                </datalist> -->
              </div>
              <div class="col-12 col-md-3">
                <label for="kota_sekolah" class="form-label">
                  Kota/Kabupaten Sekolah <span class="text-danger">*</span>
                </label>
              </div>

              <div class="col-12 col-md-9 mb-3">
                <v-select v-model="kota_sekolah" :options="kotaList_sekolah" placeholder="Pilih Kota" :searchable="true"
                  :class="{ 'is-invalid': !isInputTextValid('kota_sekolah', 2) }" ref="kota_sekolah"
                  class="form-control custom-select"></v-select>
                <div id="input-text-feedback" class="invalid-feedback" v-if="!isInputTextValid('kota_sekolah', 2)">{{
                  inputTextFeedback }}
                </div>
                <!-- <input list="kota_sekolah" v-model="currentRecord.kota_sekolah" class="form-control"
                  @change="checkInputValidity('kota_sekolah', 2)"
                  v-bind:class="{ 'is-invalid': !isInputTextValid('kota_sekolah', 2) }"   />

                <datalist id="kota_sekolah">
                  <option v-for="kota in kotaList_sekolah" :value="kota.nama" :key="kota.nama"></option>
                </datalist> -->
              </div>
              <div class="col-12 col-md-3">
                <label for="jurusan_sekolah" class="form-label">
                  Jurusan Sekolah<span class="text-danger">*</span>
                </label>
              </div>
              <div class="col-12 col-md-9 mb-3">
                <input type="text" name="jurusan_sekolah" class="form-control" id="jurusan_sekolah"
                  v-model="currentRecord.jurusan_sekolah"
                  v-bind:class="{ 'is-invalid': !isInputTextValid('jurusan_sekolah', 2) }"
                  @input="handleInputNama($event, 'jurusan_sekolah')" />
                <div id="input-text-feedback" class="invalid-feedback" v-if="!isInputTextValid('jurusan_sekolah', 2)">{{
                  inputTextFeedback }}
                </div>
              </div>
            </div>
          </div>
          <div class="border border-2 rounded-top border-bottom-0 px-2 py-2 fw-bold fst-italic">
            Pilihan
          </div>
          <div class="w-100 h-100 border rounded-bottom border-2 px-3 py-3 mb-5">
            <div class="row">
              <div class="col-12 col-md-3">
                <label for="prodi" class="form-label">
                  Program Studi yang diminati<span class="text-danger">*</span>
                </label>
              </div>
              <div class="col-12 col-md-9 mb-3">
                <select class="form-select" aria-label="Default select example" name="prodi" id="prodi" v-model="prodi"
                  @change="onOptionSelected" v-on:change="!isInputTextValid('prodi', 2)" v-bind:class="{
                    'is-invalid': !isInputTextValid('prodi', 2)
                  }">>
                  <option v-for=" prodiList in prodiList" :key="prodiList.nama" :value="prodiList.nama"
                    v-bind:disabled="prodiList.nama === 'D3 KEBIDANAN' && isMan === true">
                    {{ prodiList.nama }}
                  </option>
                </select>
                <div id="input-text-feedback" class="invalid-feedback" v-if="!isInputTextValid('prodi', 2)">{{
                  inputTextFeedback }}
                </div>
              </div>
              <div class="col-12 col-md-3">
                <label for="waktu_kuliah" class="form-label">
                  Waktu Kuliah<span class="text-danger">*</span>
                </label>
              </div>
              <div class="col-12 col-md-9 mb-3">
                <select class="form-select" aria-label="Default select example" name="waktu_kuliah" id="waktu_kuliah"
                  v-model="currentRecord.waktu_kuliah" @change="checkInputValidity('waktu_kuliah', 2)" v-bind:class="{
                    'is-invalid': !isInputTextValid('waktu_kuliah', 2)
                  }">
                  <option value="" selected disabled>Pilih
                    waktu kuliah </option>
                  <option v-for="waktu_kuliah in waktuKuliahList" :key="waktu_kuliah.nama" :value="waktu_kuliah.nama">
                    {{ waktu_kuliah.nama }}
                  </option>
                </select>
                <div id="input-text-feedback" class="invalid-feedback" v-if="!isInputTextValid('waktu_kuliah', 2)">{{
                  inputTextFeedback }}
                </div>
              </div>
              <div class="col-12 col-md-3">
                <label for="ukuran_jas_akademis" class="form-label">
                  Ukuran Jas akademis<span class="text-danger">*</span>
                </label>
              </div>
              <div class="col-12 col-md-9 mb-3">
                <select class="form-select" aria-label="Default select example" name="ukuran_jas_akademis"
                  id="ukuran_jas_akademis" v-model="currentRecord.ukuran_jas_akademis"
                  @change="checkInputValidity('ukuran_jas_akademis', 2)" v-bind:class="{
                    'is-invalid': !isInputTextValid('ukuran_jas_akademis', 2)
                  }">
                  <option value="" selected disabled>Pilih ukuran jas </option>
                  <option v-for="ukuran_jas_akademis in ukuranJasList" :key="ukuran_jas_akademis.nama"
                    :value="ukuran_jas_akademis.nama">
                    {{ ukuran_jas_akademis.nama }}
                  </option>
                </select>
                <div id="input-text-feedback" class="invalid-feedback"
                  v-if="!isInputTextValid('ukuran_jas_akademis', 2)">
                  {{
                    inputTextFeedback }}
                </div>
              </div>
            </div>
          </div>

          <div class="border border-2 rounded-top border-bottom-0 px-2 py-2 fw-bold fst-italic">
            Dokumen
          </div>
          <div class="w-100 h-100 border rounded-bottom border-2 px-3 py-3 mb-5">
            <div class="row">
              <div class="col-12 col-md-3">
                <label for="foto_ktp_siswa" class="form-label">
                  KTP <span class="text-danger"></span>
                </label>
              </div>
              <div class="col-12 col-md-9 mb-3">
                <input type="file" name="foto_ktp_siswa" class="form-control" id="foto_ktp_siswa" ref="foto_ktp_siswa"
                  accept="image/png, image/gif, image/jpeg" v-on:change="checkFileSize('foto_ktp_siswa')" />
              </div>
              <div class="col-12 col-md-3">
                <label for="foto_kartu_keluarga" class="form-label">
                  Kartu Keluarga
                  <!-- <span class="text-danger">*</span> -->
                </label>
              </div>
              <div class="col-12 col-md-9 mb-3">
                <input type="file" name="foto_kartu_keluarga" class="form-control" id="foto_kartu_keluarga"
                  ref="foto_kartu_keluarga" @change="validateFileInput('foto_kartu_keluarga', 2)"
                  accept="image/png, image/gif, image/jpeg" v-on:change="checkFileSize('foto_kartu_keluarga')">
                <!--
                  :class="{ 'is-invalid': !validateFileInput('foto_kartu_keluarga', 2) }
                  "
                  <div id="input-file-feedback" class="invalid-feedback"
                  v-if="!validateFileInput('foto_kartu_keluarga', 2)">
                  {{ inputFileFeedback }}
                </div> -->
              </div>
              <div class="col-12 col-md-3">
                <label for="foto_cover_rapor" class="form-label">
                  Cover Rapor
                  <!-- <span class="text-danger">*</span> -->
                </label>
              </div>
              <div class="col-12 col-md-9 mb-3">
                <input type="file" name="foto_cover_rapor" class="form-control" id="foto_cover_rapor"
                  ref="foto_cover_rapor" @change="validateFileInput('foto_cover_rapor', 2)"
                  accept="image/png, image/gif, image/jpeg" v-on:change="checkFileSize('foto_cover_rapor')">
                <!-- 
                  :class="{ 'is-invalid': !validateFileInput('foto_cover_rapor', 2) }"
                  <div id="input-file-feedback" class="invalid-feedback" v-if="!validateFileInput('foto_cover_rapor', 2)">
                  {{ inputFileFeedback }}
                </div> -->
              </div>
              <div class="col-12 col-md-3">
                <label for="foto_data_diri_rapor" class="form-label">
                  Data diri Rapor
                  <!-- <span class="text-danger">*</span> -->
                </label>
              </div>
              <div class="col-12 col-md-9 mb-3">
                <input type="file" name="foto_data_diri_rapor" class="form-control" id="foto_data_diri_rapor"
                  ref="foto_data_diri_rapor" @change="validateFileInput('foto_data_diri_rapor', 2)"
                  accept="image/png, image/gif, image/jpeg" v-on:change="checkFileSize('foto_data_diri_rapor')">
                <!--
                  :class="{ 'is-invalid': !validateFileInput('foto_data_diri_rapor', 2) }" 
                  <div id="input-file-feedback" class="invalid-feedback"
                  v-if="!validateFileInput('foto_data_diri_rapor', 2)">
                  {{ inputFileFeedback }}
                </div> -->
              </div>
              <div class="col-12 col-md-3">
                <label for="foto_rapor_kelas_xii_ganjil_genap" class="form-label">
                  Rapor Kelas XII Ganjil/ Genap
                  <!-- <span class="text-danger">*</span> -->
                </label>
              </div>
              <div class="col-12 col-md-9 mb-3">
                <input type="file" name="foto_rapor_kelas_xii_ganjil_genap" class="form-control"
                  id="foto_rapor_kelas_xii_ganjil_genap" ref="foto_rapor_kelas_xii_ganjil_genap"
                  @change="validateFileInput('foto_rapor_kelas_xii_ganjil_genap', 2)"
                  accept="image/png, image/gif, image/jpeg"
                  v-on:change="checkFileSize('foto_rapor_kelas_xii_ganjil_genap')">
                <!-- 
                  :class="{ 'is-invalid': !validateFileInput('foto_rapor_kelas_xii_ganjil_genap', 2) }"
                  <div id="input-file-feedback" class="invalid-feedback"
                  v-if="!validateFileInput('foto_rapor_kelas_xii_ganjil_genap', 2)">
                  {{ inputFileFeedback }}
                </div> -->
              </div>
              <div class="col-12 col-md-3">
                <label for="foto_skl_skhun" class="form-label">
                  SKL/SKHUN
                  <!-- <span class="text-danger">*</span> -->
                </label>
              </div>
              <div class="col-12 col-md-9 mb-3">
                <input type="file" name="foto_skl_skhun" class="form-control" id="foto_skl_skhun" ref="foto_skl_skhun"
                  @change="validateFileInput('foto_skl_skhun', 2)" accept="image/png, image/gif, image/jpeg"
                  v-on:change="checkFileSize('foto_skl_skhun')">
                <!-- 
                   :class="{ 'is-invalid': !validateFileInput('foto_skl_skhun', 2) }"
                  <div id="input-file-feedback" class="invalid-feedback" v-if="!validateFileInput('foto_skl_skhun', 2)">
                  {{ inputFileFeedback }}
                </div> -->
              </div>
              <div class="col-12 col-md-3">
                <label for="foto_ijazah" class="form-label">
                  Ijazah <span class="text-danger"></span>
                </label>
              </div>
              <div class="col-12 col-md-9 mb-3">
                <input type="file" name="foto_ijazah" class="form-control" id="foto_ijazah" ref="foto_ijazah"
                  accept="image/png, image/gif, image/jpeg" v-on:change="checkFileSize('foto_ijazah')" />
              </div>
            </div>
          </div>
        </div>

        <div v-show="this.page_number == 3">
          <div class="border border-2 rounded-top border-bottom-0 px-2 py-2 fw-bold fst-italic">
            Data Akun
          </div>
          <div class="w-100 h-100 border rounded-bottom border-2 px-3 py-3 mb-5">
            <div class="row">
              <div class="col-12 col-md-3">
                <label for="nis" class="form-label">
                  Email <span class="text-danger">*</span>
                </label>
              </div>
              <div class="col-12 col-md-9 col-lg-9 mb-md-3 mb-2">
                <input type="email" name="nis" class="form-control" id="nis" placeholder="Masukan alamat email anda"
                  v-model="currentRecord.email" required />
              </div>
              <!-- <div class="col-12 col-md-3 col-lg-2 mb-md-3 mb-2 text-end">
                  <button class="btn btn-danger">Verifikasi Email</button>
                </div> -->
              <div class="col-12 col-md-3">
                <label for="nis" class="form-label">
                  No Whatsapp<span class="text-danger">*</span>
                </label>
              </div>
              <div class="col-12 col-md-9 mb-3">
                <input type="text" name="nis" class="form-control" id="nis" v-model="currentRecord.whatsapp"
                  placeholder="Masukan no whatsapp anda yang aktif" @keypress="validateInput_number_only"
                  @change="validateInput('whatsapp')" required />
              </div>
              <input type="hidden" name="tahun_ajaran" v-model="currentRecord.tahun_ajaran">


            </div>
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" required />
              <label class="form-check-label" for="flexCheckDefault">
                Ya, saya setuju bahwa seluruh data yang saya isikan dan/atau unggah adalah benar, sah, legal dan/atau
                sesuai dengan keadaan dan/atau kenyataan.<span class="fw-bold"> SAYA TIDAK AKAN MENGUBAH SETELAH AKUN
                  DIBUAT</span>
              </label>
            </div>
            <div class="text-end">
              <button type="button" class="btn btn-danger me-3" @click="beforePage()">
                Kembali
              </button>
              <button ref="myButton" @click="insert()" hidden>Click me</button>
              <button type="submit" class="btn btn-primary">Register</button>
            </div>
          </div>
        </div>

        <div class="mt-4 d-flex justify-content-md-end justify-content-between position-relative " ref="buttondiv">

          <div v-show="this.page_number == 2">
            <span class="me-2 ">Kembali</span>
            <button type="button" class="btn btn-success rounded-circle py-2 me-3" @click="beforePage()">
              <i class="ri-arrow-left-line"></i>
            </button>
          </div>
          <div class=" mb-5" v-show="this.page_number == 1 || this.page_number == 2" style="visibility: hidden;">
            <span class="me-2 ">Selanjutnya</span>
            <button type="button" class="btn btn-success rounded-circle py-2" @click="nextPage()">
              <i class="ri-arrow-right-line"></i>
            </button>
          </div>
          <div class=" mb-5" v-show="this.page_number == 1 || this.page_number == 2">
            <span class="me-2">Selanjutnya</span>
            <button type="button" class="btn btn-success rounded-circle py-2" @click="nextPage()">
              <i class="ri-arrow-right-line"></i>
            </button>
          </div>
        </div>

        <!-- <button @click="insert()">hhhhh</button> -->
        <hr v-if="isMobile">
        <!-- <div class="mt-2 mb-3 text-center text-secondary">
          <i>powered by</i> <a href="https://arthaskyline.com" class="text-secondary">Artha Skyline</a>
          <div v-if="isMobile">
            this site is protected by <a href="https://policies.google.com/privacy" target="_blank"> Google
              Privacy Policy</a> and <a href="https://policies.google.com/terms" target="_blank">Terms of Service
              apply</a>
          </div>

        </div> -->
        <!-- <div>
          <button type="button" class="btn btn-primary" @click="insert()">Register</button>
        </div> -->
      </form>
    </div>
  </section>
</template>

<style></style>

<script>
// @ is an alias to /src
import axios from "axios";
import Cookies from "js-cookie";
import { base_url, goPage, ShowLoading, AlertPopup, ErrorConnectionTimeOut, base_url_wilayah_indonesia, DateNow, rupiah2, CloseLoading } from "@/assets/javascript/Function";
import NavbarDefault from "@/components/NavbarDefault.vue";
import imageCompression from 'browser-image-compression';
import { useReCaptcha } from "vue-recaptcha-v3";
import { ref } from "vue";
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

import Swal from "sweetalert2";



export default {

  setup() {
    const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();
    const myButton = ref(null);
    const isButtonClickTriggered = ref(false);

    const recaptcha = async () => {
      // Only trigger the click event if it hasn't been triggered before
      if (!isButtonClickTriggered.value) {
        isButtonClickTriggered.value = true; // Set the flag to true

        try {
          // (optional) Wait until recaptcha has been loaded.
          await recaptchaLoaded();

          // Execute reCAPTCHA with action "login".
          const token = await executeRecaptcha("login");
          // Do stuff with the received token.
          // console.log({ token });
          // localStorage.setItem('token2', token);
          Cookies.set("token2", token);
          // Trigger click event on myButton
          myButton.value.click();
        } finally {
          // Reset the flag after the function is finished, even if an error occurs
          isButtonClickTriggered.value = false;
        }

      }

    };

    return {
      recaptcha,
      myButton,
    };
  },
  data() {
    return {
      isiLainnyaPekerjaanAyah: "",
      isiLainnyaPekerjaanIbu: "",
      isLainnyaPekerjaanAyah: false,
      isLainnyaPekerjaanIbu: false,
      isWNA: false,

      tanggal_sekarang: "",
      tanggal_max_lahir: "",
      isMobile: false,
      Captcha: "visible",
      tanggal_hari_ini: "",
      tanggal_acuan_hari_ini: "",
      //
      inputTextKodePos: 'harus 5 digit',
      inputTextFeedback_ktp: 'harus 16 digit',
      inputTextFeedback_nik_ayah: 'harus 16 digit',
      inputTextFeedback_nik_ibu: 'harus 16 digit',
      inputTextFeedback_nisn: 'harus 10 digit',
      inputTextFeedback: 'Wajib diisi',
      inputFileFeedback: 'Wajib diisi',
      inputTextFeedback_validasi_ktp: 'asdas',
      ValidasiTanggal: 'Terjadi kesalahan pada tanggal, tanggal lahir maksimal ',
      name: "testing",
      selectedOption: "WNI",
      selectedOption2: "Pria",
      nikorpasspor: "No Induk Kependudukan (NIK)",
      page_number: null,

      //submit form 
      formSubmitted_1: false,
      formSubmitted_2: false,

      //foto 
      provinsi_siswa: "",
      kota_siswa: "",
      kecamatan_siswa: "",
      kelurahan_siswa: "",

      //ortu
      provinsi_ortu: "",
      kota_ortu: "",
      kecamatan_ortu: "",
      kelurahan_ortu: "",

      //sekolah 
      provinsi_sekolah: null,
      kota_sekolah: null,


      //token recaptcha
      token2: "",

      prodiList: [], // an empty array to store options
      prodi: "", // a string to store the selected option

      kotaList: [],
      provinsiList: [],
      kecamatanList: [],
      kelurahanList: [],

      kotaList_ortu: [],
      provinsiList_ortu: [],
      kecamatanList_ortu: [],
      kelurahanList_ortu: [],

      kotaList_sekolah: [],
      provinsiList_sekolah: [],
      previousProvinsi: "",
      previousKota: "",
      previousKecamatan: "",

      previousProvinsiOrtu: "",
      previousKotaOrtu: "",
      previousKecamatanOrtu: "",

      previousProvinsiSekolah: "",
      previousKotaSekolah: "",

      isMan: false,

      pendidikanTerakhirList: [
        {
          nama: "Tidak/ Belum Sekolah"
        },
        {
          nama: "Tidak Tamat SD/Sederajat"
        },
        {
          nama: "Tamat SD/Sederajat"
        },
        {
          nama: "SLTP/Sederajat"
        },
        {
          nama: "SLTA/Sederajat"
        },
        {
          nama: "Diploma I/II"
        },
        {
          nama: "Akademi/Diploma III/Sarjana Muda"
        },
        {
          nama: "Diploma IV/Strata I"
        },
        {
          nama: "Strata II"
        },
        {
          nama: "Strata III"
        }
      ],

      agamaList: [
        {
          nama: "Islam",
        },
        {
          nama: "Katolik",
        },
        {
          nama: "Protestan",
        },
        {
          nama: "Hindu",
        },
        {
          nama: "Buddha",
        },
        {
          nama: "Konghucu",
        },

      ],

      pekerjaanList: [
        {
          nama: "PNS",
        },
        {
          nama: "KARYAWAN SWASTA",
        },
        {
          nama: "WIRAUSAHA",
        },
        {
          nama: "TNI/POLRI",
        },
        {
          nama: "PETANI",
        },
        {
          nama: "NELAYAN",
        },
        {
          nama: "LAINNYA",
        },
      ],

      ukuranJasList: [
        {
          nama: "S",
        },
        {
          nama: "M",
        },
        {
          nama: "L",
        },
        {
          nama: "XL",
        },
        {
          nama: "XXL",
        },
        // {
        //   nama: "3XL",
        // },

      ],

      waktuKuliahList: [
        {
          nama: "Siang (14.00 s.d. 17.00 WIB)"
        },
        {
          nama: "Sore (17.30 s.d. 20.30 WIB)"
        }
      ],

      //foto
      foto_pas: "",

      currentRecord: {

        isPriaDisabled: true,

        //testing
        // foto_pas: "",
        // nama_lengkap: "ttttttttttt",
        // nama_depan: "test",
        // nama_tengah: "test",
        // nama_belakang: "test",
        // nik_ayah: "2222222222222222",
        // nik_ibu: "2222222222222222",
        // nisn_sekolah: "2222222222",
        // warga_negara: "WNI",
        // no_ktp: "2222222222222222",
        // no_kk: "2222222222222222",
        // tempat_lahir: "Purwakarta",
        // tanggal_lahir: "2000-09-14",
        // jenis_kelamin: "Pria",
        // alamat_siswa: "Purwakarta",
        // kota: "Purwakarta",
        // provinsi: "",
        // kecamatan: "",
        // kelurahan: "",
        // kodepos: "41115",
        // telepon_siswa: "08820000088762",
        // whatsapp: "08820000088762",
        // jumlah_bersaudara: "1",
        // anak_ke: "1",
        // agama: "Islam",
        // hobi: "Berenang",
        // nama_ayah: "Rizki",
        // no_hp_ayah: "08820000088762",
        // pekerjaan_ayah: "KARYAWAN SWASTA",
        // pendidikan_terakhir_ayah: "Tidak/ Belum Sekolah",
        // penghasilan_ayah: "1000000",
        // nama_ibu_kandung: "Rizki",
        // no_hp_ibu: "08820000088762",
        // pekerjaan_ibu: "KARYAWAN SWASTA",
        // pendidikan_terakhir_ibu: "Tidak/ Belum Sekolah",
        // penghasilan_ibu: "2000000",
        // alamat_ortu: "Purwakarta",
        // kota_ortu: "",
        // provinsi_ortu: "",
        // kecamatan_ortu: "",
        // kelurahan_ortu: "",
        // kodepos_ortu: "41115",
        // nama_sekolah: "asd",
        // jurusan_sekolah: "test",

        foto_pas: "",
        nama_lengkap: "",
        nama_depan: "",
        nama_tengah: "",
        nama_belakang: "",
        nik_ayah: "",
        nik_ibu: "",
        nisn_sekolah: "",
        warga_negara: "WNI",
        no_ktp: "",
        no_kk: "",
        tempat_lahir: "",
        tanggal_lahir: "",
        jenis_kelamin: "Pria",
        alamat_siswa: "",
        kota: "",
        provinsi: "",
        kecamatan: "",
        kelurahan: "",
        kodepos: "",
        telepon_siswa: "",
        whatsapp: "",
        jumlah_bersaudara: "",
        anak_ke: "",
        agama: "",
        hobi: "",
        nama_ayah: "",
        no_hp_ayah: "",
        pekerjaan_ayah: "",
        pendidikan_terakhir_ayah: "",
        penghasilan_ayah: "",
        nama_ibu_kandung: "",
        no_hp_ibu: "",
        pekerjaan_ibu: "",
        pendidikan_terakhir_ibu: "",
        penghasilan_ibu: "",
        alamat_ortu: "",
        kota_ortu: "",
        provinsi_ortu: "",
        kecamatan_ortu: "",
        kelurahan_ortu: "",
        kodepos_ortu: "",
        nama_sekolah: "",
        jurusan_sekolah: "",

        kota_sekolah: "",
        provinsi_sekolah: "",


        prodi_pt: "",
        waktu_kuliah: "",
        ukuran_jas_akademis: "",

        foto_kartu_keluarga: "",
        foto_cover_rapor: "",
        foto_data_diri_rapor: "",
        foto_rapor_kelas_xii_ganjil_genap: "",
        foto_skl_skhun: "",
        foto_ijazah: "",
        foto_ktp_siswa: "",


        //ta
        tahun_ajaran: "",

        nama_ijazah: "",
        email: "",

        slug: "ustb",
        admin: null,
      },

      adminList: [],
    };
  },
  name: "HomeView",
  components: {
    NavbarDefault, 'v-select': vSelect
  },

  watch: {
    'provinsi_siswa': function () {
      if (this.provinsi_siswa == null) {
        this.currentRecord.provinsi = "";
      } else {
        this.currentRecord.provinsi = this.provinsi_siswa.nama;
        this.kabupaten_index(this.provinsi_siswa.value, 'siswa');
        this.kota_siswa = null;
        this.kecamatan_siswa = null;
        this.kelurahan_siswa = null;
      }

    },
    'kota_siswa': function () {
      if (this.kota_siswa == null) {
        this.currentRecord.kota = "";
      } else {
        this.currentRecord.kota = this.kota_siswa.nama;
        this.kecamatan_index(this.kota_siswa.value, 'siswa');
        this.kecamatan_siswa = null;
        this.kelurahan_siswa = null;
      }
    },
    'kecamatan_siswa': function () {

      if (this.kecamatan_siswa == null) {
        this.currentRecord.kecamatan = "";
      } else {

        this.currentRecord.kecamatan = this.kecamatan_siswa.nama;
        this.kelurahan_index(this.kecamatan_siswa.value, 'siswa');
        this.kelurahan_siswa = null;
      }
    },
    'kelurahan_siswa': function () {

      if (this.kelurahan_siswa == null) {
        this.currentRecord.kelurahan = "";
      } else {

        this.currentRecord.kelurahan = this.kelurahan_siswa.nama;
      }

    },
    'provinsi_ortu': function () {
      if (this.provinsi_ortu == null) {
        this.currentRecord.provinsi_ortu = "";
      } else {
        this.currentRecord.provinsi_ortu = this.provinsi_ortu.nama;
        this.kabupaten_index(this.provinsi_ortu.value, 'ortu');
        this.kota_ortu = null;
        this.kecamatan_ortu = null;
        this.kelurahan_ortu = null;
      }

    },
    'kota_ortu': function () {
      if (this.kota_ortu == null) {
        this.currentRecord.kota_ortu = "";
      } else {
        this.currentRecord.kota_ortu = this.kota_ortu.nama;
        this.kecamatan_index(this.kota_ortu.value, 'ortu');
        this.kecamatan_ortu = null;
        this.kelurahan_ortu = null;
      }
    },
    'kecamatan_ortu': function () {

      if (this.kecamatan_ortu == null) {
        this.currentRecord.kecamatan_ortu = "";
      } else {

        this.currentRecord.kecamatan_ortu = this.kecamatan_ortu.nama;
        this.kelurahan_index(this.kecamatan_ortu.value, 'ortu');
        this.kelurahan_ortu = null;
      }

    },
    'kelurahan_ortu': function () {
      if (this.kelurahan_ortu == null) {
        this.currentRecord.kelurahan_ortu = "";
      } else {
        this.currentRecord.kelurahan_ortu = this.kelurahan_ortu.nama;
      }

    },
    'provinsi_sekolah': function () {
      if (this.provinsi_sekolah == null) {
        this.currentRecord.provinsi_sekolah = "";
      } else {
        this.currentRecord.provinsi_sekolah = this.provinsi_sekolah.nama;
        this.kabupaten_index(this.provinsi_sekolah.value, 'sekolah');
        this.kota_sekolah = null;
      }

    },
    'kota_sekolah': function () {
      if (this.kota_sekolah == null) {
        this.currentRecord.kota_sekolah = "";
      } else {
        this.currentRecord.kota_sekolah = this.kota_sekolah.nama;
      }
    },

    'currentRecord.nik_ayah': {
      handler() {
        this.currentRecord.nik_ayah = this.validateNumberString('nik_ayah');
      }
    },
    'currentRecord.nik_ibu': {
      handler() {
        this.currentRecord.nik_ibu = this.validateNumberString('nik_ibu');
      }
    },

  },
  methods: {
    validateNumberString(field) {
      // Check if the string contains only digits (0-9)
      const isValid = /^\d+$/.test(this.currentRecord[field]);
      // If the string is valid, return the string, otherwise return an empty string
      return isValid ? this.currentRecord[field] : '';
    },
    validasi_anak_ke_berapa() {
      if (this.currentRecord.anak_ke > this.currentRecord.jumlah_bersaudara) {
        AlertPopup('error', '', 'Urutan anak tidak sesuai melebihi jumlah saudara', 1500, false)
        this.currentRecord.anak_ke = 1;
      }
      if (this.currentRecord.anak_ke == 0) {
        this.currentRecord.anak_ke = 1;
      }
    },
    validateInput(field) {
      // Regular expression to check if the input contains any character or symbol
      const regex = /[^\d]/;

      if (regex.test(this.currentRecord[field])) {
        // Clear the input value if it contains a character or symbol
        this.currentRecord[field] = '';
      }
    },

    validasi_jumlah_saudara() {
      if (this.currentRecord.jumlah_bersaudara == 0) {
        this.currentRecord.jumlah_bersaudara = 1;
      }
    },


    handleInputNama(event, nama_field) {
      const input = event.target.value;
      const regex = /^[a-zA-Z\s'.,]*$/; // Regular expression to match alphabet characters, space, single quote, dot, and comma

      if (!regex.test(input)) {
        // If the input doesn't match the regex, remove the non-alphabet characters (including numbers)
        this.currentRecord[nama_field] = input.replace(/[^a-zA-Z\s'.,]/g, '');
      }
    },
    handleInputNama2(event, nama_field) {
      const input = event.target.value;
      const regex = /^[a-zA-Z\s'.,]*$/; // Regular expression to match alphabet characters, space, single quote, dot, and comma

      if (!regex.test(input)) {
        // If the input doesn't match the regex, remove the non-alphabet characters (including numbers)
        this[nama_field] = input.replace(/[^a-zA-Z\s'.,]/g, '');
      }
    },
    handleInputkodePos(event) {
      const input = event.target.value;
      const regex = /^\d*$/; // Regular expression to match only numbers

      if (!regex.test(input)) {
        // If the input doesn't match the regex, remove non-number characters
        this.currentRecord.kodepos = input.replace(/\D/g, '');
      }
    },
    handleInputkodePosOrtu(event) {
      const input = event.target.value;
      const regex = /^\d*$/; // Regular expression to match only numbers

      if (!regex.test(input)) {
        // If the input doesn't match the regex, remove non-number characters
        this.currentRecord.kodepos_ortu = input.replace(/\D/g, '');
      }
    },
    formatCurrency(event) {
      // Remove any non-digit characters
      let value = event.target.value.replace(/[^\d.]/g, "");

      // Update the data property with the formatted value
      this.currentRecord.penghasilan_ayah = rupiah2(value);
    },
    // saveData() {
    //   this.currentRecord.warga_negara = this.selectedOption;
    //   this.currentRecord.jenis_kelamin = this.selectedOption2;
    //   this.currentRecord.prodi = this.prodi;
    //   const formData = new FormData();
    //   formData.append('myfile', this.$refs.foto_pas.files[0]);
    //   localStorage.setItem("data_diri", JSON.stringify(this.currentRecord));
    //   goPage("data_akun");

    // },
    indexData() {
      if (
        Cookies.get("nama_jurusan") != "" ||
        Cookies.get("nama_jurusan") != null
      ) {
        this.prodi = Cookies.get("nama_jurusan");
        if (this.prodi == 'D3 KEBIDANAN') {
          this.isPriaDisabled = true;
          this.selectedOption2 = 'Wanita';
          this.currentRecord.jenis_kelamin = 'Wanita';
        }


      }

    },
    goPage,
    updateSelectedOption(option) {
      this.selectedOption = option;
      if (option == "WNI") {
        this.nikorpasspor = 'No Induk Kependudukan (NIK)';
        this.inputTextFeedback_ktp = 'harus 16 digit';
      } else {
        this.nikorpasspor = 'No Paspor';
        this.currentRecord.no_kk = '';
        this.inputTextFeedback_ktp = 'Wajib Diisi';
      }

      this.currentRecord.no_ktp = "";

      this.currentRecord.warga_negara = option;
    },
    updateSelectedOption2(option) {
      this.selectedOption2 = option;
      this.currentRecord.jenis_kelamin = option;
    },
    onOptionSelected() {
      // get the selected option value
      Cookies.set("nama_jurusan", this.prodi);
    },

    checkInputValidity(pilihan) {
      const enteredValue = this.currentRecord['' + pilihan];
      let validOptions;

      if (pilihan == 'agama') {
        validOptions = this.agamaList.map(option => option.nama);
      }

      if (pilihan == 'kota') {
        if (this.currentRecord.kota !== this.previousKota) {
          this.currentRecord.kecamatan = ''; // Reset the value of `kabupaten` input
          this.currentRecord.kelurahan = '';
        }
        this.previousKota = this.currentRecord.kota;

        validOptions = this.kotaList.map(option => option.nama);
        if (!validOptions.includes(enteredValue)) {
          this.currentRecord['' + pilihan] = '';
        } else {
          let filter = this.kotaList.filter(kota => kota.nama.includes(this.currentRecord.kota));
          this.kecamatan_index(filter[0].id, 'siswa');
        }
      }
      if (pilihan == 'kecamatan') {
        if (this.currentRecord.kecamatan !== this.previousKecamatan) {
          this.currentRecord.kelurahan = '';
        }
        this.previousKecamatan = this.currentRecord.kecamatan;


        validOptions = this.kecamatanList.map(option => option.nama);
        if (!validOptions.includes(enteredValue)) {
          this.currentRecord['' + pilihan] = '';
        } else {
          let filter = this.kecamatanList.filter(provinsi => provinsi.nama.includes(this.currentRecord.kecamatan));
          this.kelurahan_index(filter[0].id, 'siswa');
        }
      }
      if (pilihan == 'kelurahan') {



        validOptions = this.kelurahanList.map(option => option.nama);
        if (!validOptions.includes(enteredValue)) {
          this.currentRecord['' + pilihan] = '';
        }
      }

      if (pilihan == 'provinsi_ortu') {

        if (this.currentRecord.provinsi_ortu !== this.previousProvinsiOrtu) {
          this.currentRecord.kota_ortu = ''; // Reset the value of `kota` input
          this.currentRecord.kecamatan_ortu = ''; // Reset the value of `kabupaten` input
          this.currentRecord.kelurahan_ortu = '';
        }
        this.previousProvinsiOrtu = this.currentRecord.provinsi_ortu;

        validOptions = this.provinsiList_ortu.map(option => option.nama);
        if (!validOptions.includes(enteredValue)) {
          this.currentRecord['' + pilihan] = '';
        } else {
          let filter = this.provinsiList_ortu.filter(provinsi => provinsi.nama.includes(this.currentRecord.provinsi_ortu));
          this.kabupaten_index(filter[0].id, 'ortu');
        }
      }
      if (pilihan == 'kota_ortu') {

        if (this.currentRecord.kota_ortu !== this.previousKotaOrtu) {
          this.currentRecord.kecamatan_ortu = ''; // Reset the value of `kabupaten` input
          this.currentRecord.kelurahan_ortu = '';
        }
        this.previousKotaOrtu = this.currentRecord.kota_ortu;

        validOptions = this.kotaList_ortu.map(option => option.nama);
        if (!validOptions.includes(enteredValue)) {
          this.currentRecord['' + pilihan] = '';
        } else {
          let filter = this.kotaList_ortu.filter(kota => kota.nama.includes(this.currentRecord.kota_ortu));
          this.kecamatan_index(filter[0].id, 'ortu');
        }
      }
      if (pilihan == 'kecamatan_ortu') {
        if (this.currentRecord.kecamatan_ortu !== this.previousKecamatanOrtu) {
          this.currentRecord.kelurahan_ortu = '';
        }
        this.previousKecamatanOrtu = this.currentRecord.kecamatan_ortu;
        validOptions = this.kecamatanList_ortu.map(option => option.nama);
        if (!validOptions.includes(enteredValue)) {
          this.currentRecord['' + pilihan] = '';
        } else {
          let filter = this.kecamatanList_ortu.filter(provinsi => provinsi.nama.includes(this.currentRecord.kecamatan_ortu));
          this.kelurahan_index(filter[0].id, 'ortu');
        }
      }
      if (pilihan == 'kelurahan_ortu') {
        validOptions = this.kelurahanList_ortu.map(option => option.nama);
        if (!validOptions.includes(enteredValue)) {
          this.currentRecord['' + pilihan] = '';

        }
      }

      if (pilihan == 'provinsi_sekolah') {
        if (this.currentRecord.provinsi_sekolah !== this.previousProvinsiSekolah) {
          this.currentRecord.kota_sekolah = ''; // Reset the value of `kota` input
        }
        this.previousProvinsiSekolah = this.currentRecord.provinsi_sekolah;

        validOptions = this.provinsiList_sekolah.map(option => option.nama);
        if (!validOptions.includes(enteredValue)) {
          this.currentRecord['' + pilihan] = '';
        } else {
          let filter = this.provinsiList_sekolah.filter(provinsi => provinsi.nama.includes(this.currentRecord.provinsi_sekolah));
          this.kabupaten_index(filter[0].id, 'sekolah');
        }
      }
      if (pilihan == 'kota_sekolah') {
        validOptions = this.kotaList_sekolah.map(option => option.nama);
        if (!validOptions.includes(enteredValue)) {
          this.currentRecord['' + pilihan] = '';
        }
      }

      if (pilihan == 'prodi') {
        validOptions = this.prodiList.map(option => option.nama);
        if (!validOptions.includes(enteredValue)) {
          this.prodi = '';
        }
      }


      if (pilihan == 'pendidikan_terakhir_ayah' || pilihan == 'pendidikan_terakhir_ibu') {
        validOptions = this.pendidikanTerakhirList.map(option => option.nama);
      }
      if (pilihan == 'pekerjaan_ayah' || pilihan == 'pekerjaan_ibu') {

        if (pilihan == 'pekerjaan_ayah') {

          if (this.currentRecord.pekerjaan_ayah == 'LAINNYA') {
            this.isLainnyaPekerjaanAyah = true;
          } else {
            this.isLainnyaPekerjaanAyah = false;
          }
        } else {
          if (this.currentRecord.pekerjaan_ibu == 'LAINNYA') {
            this.isLainnyaPekerjaanIbu = true;
          } else {
            this.isLainnyaPekerjaanIbu = false;
          }
        }
        validOptions = this.pekerjaanList.map(option => option.nama);
      }
      if (pilihan == 'ukuran_jas_akademis' || pilihan == 'ukuran_jas_akademis') {
        validOptions = this.ukuranJasList.map(option => option.nama);
      }


    },
    async nextPage() {
      if (this.page_number == 1) {
        this.formSubmitted_1 = true;
        if (this.validateFileInput('foto_pas')
          && this.isInputTextValid('nama_lengkap')
          && this.isInputTextValid('nama_depan')
          && this.isInputTextValid('nama_tengah')
          && this.isInputTextValid('nama_belakang')
          && this.isInputTextValid('nisn_sekolah')
          && this.isInputTextValid('no_ktp')
          && this.isInputTextValid('no_kk')
          && this.isInputTextValid('tempat_lahir')
          && this.isInputTextValid('tanggal_lahir')
          && this.isInputTextValid('alamat_siswa')
          && this.isInputTextValid('provinsi')
          && this.isInputTextValid('kota')
          && this.isInputTextValid('kecamatan')
          && this.isInputTextValid('kelurahan')
          && this.isInputTextValid('kodepos')
          // && this.isInputTextValid('telepon_siswa')
          && this.isInputTextValid('whatsapp')
          && this.isInputTextValid('agama')
          && this.isInputTextValid('nama_ayah')
          && this.isInputTextValid('nik_ayah')
          && this.isInputTextValid('no_hp_ayah')
          && this.isInputTextValid('pekerjaan_ayah')
          && this.isInputTextValid('pendidikan_terakhir_ayah')
          && this.isInputTextValid('nama_ibu_kandung')
          && this.isInputTextValid('nik_ibu')
          && this.isInputTextValid('no_hp_ibu')
          && this.isInputTextValid('pekerjaan_ibu')
          && this.isInputTextValid('pendidikan_terakhir_ibu')
          && this.isInputTextValid('alamat_ortu')
          && this.isInputTextValid('provinsi_ortu')
          && this.isInputTextValid('kota_ortu')
          && this.isInputTextValid('kecamatan_ortu')
          && this.isInputTextValid('kelurahan_ortu')
          && this.isInputTextValid('kodepos_ortu')
          && this.isInputTextValid('admin')

        ) {
          this.page_number = this.page_number + 1;
          window.scrollTo({
            top: 0,
            behavior: 'auto' // Optionally, you can use 'auto' for instant scrolling
          });
          this.isMan = false;
          if (this.currentRecord.jenis_kelamin == 'Pria') {
            if (this.currentRecord.prodi_pt == 'D3 KEBIDANAN') {
              this.currentRecord.prodi_pt = "";
            }
            this.isMan = true;
          }

        } else {
          await new Promise(resolve => setTimeout(resolve));
          this.scrollToInvalidInput();
        }


      } else if (this.page_number == 2) {

        this.formSubmitted_2 = true;
        if (this.isInputTextValid('nama_sekolah', 2)
          && this.isInputTextValid('provinsi_sekolah', 2)
          && this.isInputTextValid('kota_sekolah', 2)
          && this.isInputTextValid('jurusan_sekolah', 2)
          && this.isInputTextValid('waktu_kuliah', 2)
          && this.isInputTextValid('ukuran_jas_akademis', 2)
          && this.isInputTextValid('prodi', 2)


          // && this.validateFileInput('foto_kartu_keluarga', 2)
          // && this.validateFileInput('foto_cover_rapor', 2)
          // && this.validateFileInput('foto_data_diri_rapor', 2)
          // && this.validateFileInput('foto_rapor_kelas_xii_ganjil_genap', 2)
          // && this.validateFileInput('foto_skl_skhun', 2)
        ) {
          this.page_number = this.page_number + 1;
          window.scrollTo({
            top: 0,
            behavior: 'auto' // Optionally, you can use 'auto' for instant scrolling
          });
        } else {
          await new Promise(resolve => setTimeout(resolve));
          this.scrollToInvalidInput();
        }
      } else {
        this.page_number = this.page_number + 1;

      }


    },
    beforePage() {
      this.page_number = this.page_number - 1;
      window.scrollTo({
        top: 0,
        behavior: 'auto' // Optionally, you can use 'auto' for instant scrolling
      });
    },
    async insert() {

      // console.log(Cookies.get('token2'));

      ShowLoading();
      const formData = new FormData();
      formData.append("slug", "ustb");
      formData.append("url", "form_mandiri");
      formData.append("token", Cookies.get('token2'));
      formData.append("foto_pas", this.$refs.foto_pas.files[0]);

      if (this.$refs.foto_kartu_keluarga.files[0] != undefined) {
        formData.append("foto_kartu_keluarga", this.$refs.foto_kartu_keluarga.files[0]);
      }
      if (this.$refs.foto_cover_rapor.files[0] != undefined) {
        formData.append("foto_cover_rapor", this.$refs.foto_cover_rapor.files[0]);
      }

      if (this.$refs.foto_data_diri_rapor.files[0] != undefined) {
        formData.append("foto_data_diri_rapor", this.$refs.foto_data_diri_rapor.files[0]);
      }

      if (this.$refs.foto_rapor_kelas_xii_ganjil_genap.files[0]) {
        formData.append("foto_rapor_kelas_xii_ganjil_genap", this.$refs.foto_rapor_kelas_xii_ganjil_genap.files[0]);
      }

      if (this.$refs.foto_skl_skhun.files[0]) {
        formData.append("foto_skl_skhun", this.$refs.foto_skl_skhun.files[0]);
      }

      if (this.$refs.foto_ijazah.files[0]) {
        formData.append("foto_ijazah", this.$refs.foto_ijazah.files[0]);
      }

      if (this.$refs.foto_ktp_siswa.files[0] != undefined) {
        formData.append("foto_ktp_siswa", this.$refs.foto_ktp_siswa.files[0]);
      }

      //jenis kelamin
      this.currentRecord.jenis_kelamin = this.selectedOption2;
      this.currentRecord.warga_negara = this.selectedOption;
      this.currentRecord.prodi_pt = this.prodi;
      if (this.currentRecord.pekerjaan_ayah == "LAINNYA") {
        this.currentRecord.pekerjaan_ayah = this.isiLainnyaPekerjaanAyah;
      }
      if (this.currentRecord.pekerjaan_ibu == "LAINNYA") {
        this.currentRecord.pekerjaan_ibu = this.isiLainnyaPekerjaanIbu;
      }

      Object.entries(this.currentRecord).forEach(([key, value]) => {
        formData.append(key, value);
      });

      formData.delete("admin");
      formData.append("admin", this.currentRecord.admin.value);

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + "form_mandiri";
      } else {
        this.api = base_url + "form_mandiri";
      }

      // Add a new record to the API
      axios
        .post(this.api, formData)
        .then((response) => {
          if (response.data.status == true) {
            // AlertPopupGoToURL("", "Data berhasil dimasukkan", true, response.data.link_invoice);
            // AlertPopup("", "Data berhasil dimasukkan", true, '');
            AlertPopup("success", "", 'Data berhasil dimasukkan', 1500, false);
            setTimeout(() => {
              goPage('');
            }, 1500);
          }
          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },

    async compressImage(file) {
      const options = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 800,
        useWebWorker: true
      };

      try {
        const compressedFile = await imageCompression(file, options);
        return this.blobToFile(compressedFile, compressedFile.name);
        // const imageUrl = URL.createObjectURL(compressedFile);
        // console.log(imageUrl);
        // this.imageUrl = imageUrl;
      } catch (error) {
        console.log('compression error:', error);
      }


    },
    onFileChange(event) {
      const file = event.target.files[0];
      this.imageFile = file;
      this.imageUrl = URL.createObjectURL(file);
    },
    blobToFile(blob, fileName) {
      const options = {
        type: blob.type,
        lastModified: blob.lastModified
      };

      const file = new File([blob], fileName, options);
      return file;
    },
    provinsi_index() {
      axios
        .post(base_url_wilayah_indonesia + "provinsi_index", '')
        .then((response) => {
          if (response.data.status == true) {
            // AlertPopup("success", response.data.message, "", 1500, false);
            this.provinsiList = response.data.data.map((item) => ({
              nama: item.nama,
              value: item.id,
              label: item.nama,
            }));

            this.provinsiList_ortu = response.data.data.map((item) => ({
              nama: item.nama,
              value: item.id,
              label: item.nama,
            }));
            this.provinsiList_sekolah = response.data.data.map((item) => ({
              nama: item.nama,
              value: item.id,
              label: item.nama,
            }));
          }
          if (response.data.status == false) {
            // AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },
    kabupaten_index(id, jenis) {
      const formData = new FormData();
      formData.append("id", id);
      axios
        .post(base_url_wilayah_indonesia + "kabupaten_index", formData)
        .then((response) => {
          if (response.data.status == true) {
            if (jenis == "ortu") {
              this.kotaList_ortu = response.data.data.map((item) => ({
                nama: item.nama,
                value: item.id,
                label: item.nama,
              }));
            }
            if (jenis == "siswa") {
              this.kotaList = response.data.data.map((item) => ({
                nama: item.nama,
                value: item.id,
                label: item.nama,
              }));
            }
            if (jenis == "sekolah") {
              this.kotaList_sekolah = response.data.data.map((item) => ({
                nama: item.nama,
                value: item.id,
                label: item.nama,
              }));
            }
          }
          if (response.data.status == false) {
            //as
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },
    kecamatan_index(id, jenis) {
      const formData = new FormData();
      formData.append("id", id);
      axios
        .post(base_url_wilayah_indonesia + "kecamatan_index", formData)
        .then((response) => {
          if (response.data.status == true) {

            if (jenis == "ortu") {
              this.kecamatanList_ortu = response.data.data.map((item) => ({
                nama: item.nama,
                value: item.id,
                label: item.nama,
              }));
            }
            if (jenis == "siswa") {
              // this.kecamatanList = response.data.data;
              this.kecamatanList = response.data.data.map((item) => ({
                nama: item.nama,
                value: item.id,
                label: item.nama,
              }));
            }
          }
          if (response.data.status == false) {
            //
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },
    kelurahan_index(id, jenis) {

      const formData = new FormData();
      formData.append("id", id);
      axios
        .post(base_url_wilayah_indonesia + "kelurahan_index", formData)
        .then((response) => {
          if (response.data.status == true) {
            if (jenis == "ortu") {
              this.kelurahanList_ortu = response.data.data.map((item) => ({
                nama: item.nama,
                value: item.id,
                label: item.nama,
              }));
            }
            if (jenis == "siswa") {
              this.kelurahanList = response.data.data.map((item) => ({
                nama: item.nama,
                value: item.id,
                label: item.nama,
              }));
            }

          }
          if (response.data.status == false) {
            //
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },
    //form input validation
    scrollToInvalidInput() {
      const invalidInput = document.querySelector('.is-invalid');
      if (invalidInput) {
        invalidInput.scrollIntoView({ block: 'center' });
        window.scrollBy(0, -100); // add 50px padding to the top
        invalidInput.focus();
      }
    },
    isInputTextValid(field, page) {
      if (page == undefined && this.formSubmitted_1 == false) {
        //cek length ktp
        if (field == 'no_ktp') {
          if (this.currentRecord.warga_negara == 'WNA') {
            if (this.currentRecord.no_ktp.length != 0) {
              return true;
            }
          } else {
            if (this.currentRecord.no_ktp.length == 16) {
              return true;
            }
          }
        }

        //cek length ktp
        if (field == 'nik_ayah') {
          if (this.currentRecord.warga_negara == 'WNA') {
            if (this.currentRecord.nik_ayah.length != 0) {
              return true;
            }
          } else {
            if (this.currentRecord.nik_ayah.length == 16) {
              return true;
            }
          }
        }
        if (field == 'nik_ibu') {
          if (this.currentRecord.warga_negara == 'WNA') {
            if (this.currentRecord.nik_ibu.length != 0) {
              return true;
            }
          } else {
            if (this.currentRecord.nik_ibu.length == 16) {
              return true;
            }
          }
        }

        if (field == 'kodepos') {
          if (this.currentRecord.kodepos.length == 5) {
            return true;
          }
        }
        if (field == 'kodepos_ortu') {
          if (this.currentRecord.kodepos_ortu.length == 5) {
            return true;
          }
        }
        //cek no kk
        if (field == 'no_kk') {
          if (this.currentRecord.warga_negara == "WNA") {
            return true;
          }
          if (this.currentRecord.no_kk.length == 16) {
            return true;
          }
        }

        //cek no nisn
        if (field == 'nisn_sekolah') {
          if (this.currentRecord.nisn_sekolah.length == 10) {
            return true;
          }
        }

        if (field == "admin") {
          if (this.currentRecord.admin != null) {
            return true;
          }
        }
        if (field == "provinsi") {
          if (this.provinsi_siswa != null) {
            return true;
          }
        }
        if (field == "kota") {
          if (this.kota_siswa != null) {
            return true;
          }
        }
        if (field == "kecamatan") {
          if (this.kecamatan_siswa != null) {
            return true;
          }
        }
        if (field == "kelurahan") {
          if (this.kelurahan_siswa != null) {
            return true;
          }
        }
        if (field == "provinsi_ortu") {
          if (this.provinsi_ortu != null) {
            return true;
          }
        }
        if (field == "kota_ortu") {
          if (this.kota_ortu != null) {
            return true;
          }
        }
        if (field == "kecamatan_ortu") {
          if (this.kecamatan_ortu != null) {
            return true;
          }
        }
        if (field == "kelurahan_ortu") {
          if (this.kelurahan_ortu != null) {
            return true;
          }
        }

        if (field == "tanggal_lahir") {
          // Calculate the difference in milliseconds between the two dates
          var timeDiff2 = Math.abs(Date.parse(this.tanggal_hari_ini) - Date.parse(this.currentRecord.tanggal_lahir));
          // Convert the time difference to years
          var yearsDiff2 = Math.floor(timeDiff2 / (1000 * 3600 * 24 * 365));

          if (yearsDiff2 >= 17 && yearsDiff2 <= 45) {
            return true;
          }
        }
        return true;
      }

      if (page == 2 && this.formSubmitted_2 == false) {

        if (field == "provinsi_sekolah") {
          if (this.provinsi_sekolah == null) {

            return true;
          }
        }
        if (field == "kota_sekolah") {
          if (this.kota_sekolah == null) {
            return true;
          }
        }
        return true;
      }


      // if (page == 2 && this.formSubmitted_2 == true) {

      //   if (field == "provinsi_sekolah") {
      //     if (this.provinsi_sekolah != null) {
      //       return false;
      //     }
      //   }
      //   if (field == "kota_sekolah") {
      //     if (this.kota_sekolah != null) {
      //       return false;
      //     }
      //   }
      //   return true;
      // }



      //cek length no ktp

      if (field == 'no_ktp') {
        if (this.currentRecord.warga_negara == 'WNA') {
          if (this.currentRecord.no_ktp.length == 0) {
            return false;
          }
        } else {
          if (this.currentRecord.no_ktp.length != 16) {
            return false;
          }
        }

      }

      if (field == 'nik_ayah') {
        if (this.currentRecord.warga_negara == 'WNA') {
          if (this.currentRecord.nik_ayah.length == 0) {
            return false;
          }
        } else {
          if (this.currentRecord.nik_ayah.length != 16) {
            return false;
          }
        }

      }

      if (field == 'nik_ibu') {
        if (this.currentRecord.warga_negara == 'WNA') {
          if (this.currentRecord.nik_ibu.length == 0) {
            return false;
          }
        } else {
          if (this.currentRecord.nik_ibu.length != 16) {
            return false;
          }
        }

      }

      //cek length no kk
      if (field == 'no_kk') {
        if (this.currentRecord.warga_negara == "WNA") {
          return true;
        }
        if (this.currentRecord.no_kk.length != 16) {
          return false;
        }
      }

      //cek nisn sekolah
      if (field == 'nisn_sekolah') {
        if (this.currentRecord.nisn_sekolah.length != 10) {
          return false;
        }
      }

      if (field == "admin") {
        if (this.currentRecord.admin == null) {
          return false;
        }
      }

      if (field == "provinsi") {
        if (this.provinsi_siswa == null) {
          return false;
        }
      }

      if (field == "kota") {
        if (this.kota_siswa == null) {
          return false;
        }
      }
      if (field == "kecamatan") {
        if (this.kecamatan_siswa == null) {
          return false;
        }
      }
      if (field == "kelurahan") {
        if (this.kelurahan_siswa == null) {
          return false;
        }
      }
      if (field == "provinsi_ortu") {
        if (this.provinsi_ortu == null) {
          return false;
        }
      }
      if (field == "kota_ortu") {
        if (this.kota_ortu == null) {
          return false;
        }
      }
      if (field == "kecamatan_ortu") {
        if (this.kecamatan_ortu == null) {
          return false;
        }
      }
      if (field == "kelurahan_ortu") {
        if (this.kelurahan_ortu == null) {
          return false;
        }
      }

      if (field == 'kodepos') {
        if (this.currentRecord.kodepos.length < 5 || this.currentRecord.kodepos.length > 5) {
          return false;
        }
      }

      if (field == 'kodepos_ortu') {
        if (this.currentRecord.kodepos_ortu.length < 5 || this.currentRecord.kodepos_ortu.length > 5) {
          return false;
        }
      }

      if (field == "tanggal_lahir") {
        // Calculate the difference in milliseconds between the two dates
        var timeDiff = Math.abs(Date.parse(this.tanggal_hari_ini) - Date.parse(this.currentRecord.tanggal_lahir));
        // Convert the time difference to years
        var yearsDiff = Math.floor(timeDiff / (1000 * 3600 * 24 * 365));


        if (yearsDiff < 17 || yearsDiff > 45) {
          this.ValidasiTanggal = "";
          this.ValidasiTanggal = "minimal 17 tahun dan maksimal 45 tahun";
          return false;
        }
      }

      if (field == "provinsi_sekolah") {
        if (this.formSubmitted_2 == false) {
          if (this.provinsi_sekolah == null) {
            return true;
          } else {
            return false;
          }
        } else {
          if (this.provinsi_sekolah == null) {
            return false;
          } else {
            return true;
          }
        }
      }

      if (field == "kota_sekolah") {
        if (this.formSubmitted_2 == false) {
          if (this.kota_sekolah == null) {
            return true;
          } else {
            return false;
          }
        } else {
          if (this.kota_sekolah == null) {
            return false;
          } else {
            return true;
          }
        }

      }

      if (field == "prodi") {
        const indexprodi = this.prodiList.findIndex(
          (record) => record.nama === this.prodi
        )
        if (indexprodi == -1) {
          return false;
        } else {
          return true;
        }
      }

      if (field == 'waktu_kuliah') {
        if (this.currentRecord.waktu_kuliah == "") {
          return false;
        }
      }
      if (field == 'ukuran_jas_akademis') {
        if (this.currentRecord.ukuran_jas_akademis == "") {
          return false;
        }
      }

      if (field == 'admin') {
        return true;
      } else {
        return this.currentRecord && this.currentRecord['' + field] && this.currentRecord['' + field].trim() !== '';
      }
    },
    isInputNumberValid() {
      return this.inputNumber >= 0 && this.inputNumber <= 100;
    },
    validateFileInput(field, page) {
      if (page == undefined && this.formSubmitted_1 == false) {
        return true;
      }

      if (page == 2 && this.formSubmitted_2 == false) {
        return true;
      }


      const fileInput = this.$refs[field];
      const file = fileInput.files[0];


      if (file == undefined) {
        fileInput.classList.add('is-invalid');
        return false;
      }


      const validTypes = ['image/png', 'image/gif', 'image/jpeg'];
      if (!validTypes.includes(file.type)) {
        fileInput.classList.add('is-invalid');
        return false;
      }

      fileInput.classList.remove('is-invalid');
      return true;
    },
    tahun_ajaran() {
      const formData = new FormData();
      formData.append("slug", "ustb");
      formData.append("url", "form_ta");

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + "form_ta";
      } else {
        this.api = base_url + "form_ta";
      }
      axios
        .post(this.api, formData)
        .then((response) => {
          if (response.data.status == true) {
            this.currentRecord.tahun_ajaran = response.data.data[0].tahun_ajaran;

            this.adminList = response.data.data_marketing.map((item) => ({
              nama: item.nama_lengkap,
              value: item.id,
              label: item.nama_lengkap,
            }));

            // localStorage.setItem('tahun_ajaran', response.data.data[0].tahun_ajaran);

            // if (response.data.data[0].tanggal_mulai <= this.tanggal_acuan_hari_ini && this.tanggal_acuan_hari_ini <= response.data.data[0].tanggal_selesai) {
            //   //do nothing
            // } else {
            //   //do nothing
            //   goPage('form_pendaftaran_ditutup');
            // }
          }
          if (response.data.status == false) {
            goPage('form_pendaftaran_ditutup');
            // AlertPopup("error", response.data.message, "", false)
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },
    SettingCaptchaView() {

      if (this.isMobile) {
        this.Captcha = "hidden"
      }

      const badgeElement = document.querySelector('.grecaptcha-badge');
      if (this)
        if (badgeElement) {

          badgeElement.style.visibility = this.Captcha;
          badgeElement.style.top = '150px';
        } else {
          // Create a new MutationObserver
          const observer = new MutationObserver(() => {
            // When the reCAPTCHA badge element appears, hide it
            const badgeElement = document.querySelector('.grecaptcha-badge');
            if (badgeElement) {
              badgeElement.style.visibility = this.Captcha;
              badgeElement.style.top = '150px';

              // Disconnect the observer once the badge element has been hidden
              observer.disconnect();
            }
          });

          // Start observing changes to the DOM
          observer.observe(document.body, {
            childList: true,
            subtree: true,
          });
        }
    },
    validateInput_number_only(event) {

      // get the key code of the pressed key
      const keyCode = event.keyCode || event.which;
      // allow digits, backspace, and delete keys
      if (keyCode < 48 || keyCode > 57) {
        event.preventDefault();
      }
    },

    validateInput_number_only_no_ktp(event) {

      // get the key code of the pressed key
      const keyCode = event.keyCode || event.which;

      if (this.currentRecord.warga_negara == 'WNA') {
        // allow alphabets (A-Z and a-z) and numbers (0-9)
        if ((keyCode >= 65 && keyCode <= 90) || (keyCode >= 97 && keyCode <= 122) || (keyCode >= 48 && keyCode <= 57)) {
          // valid input
          return;
        }

        // prevent input for other characters
        event.preventDefault();
      } else {
        // allow digits, backspace, and delete keys
        if (keyCode < 48 || keyCode > 57) {
          event.preventDefault();
        }
      }

    },

    async checkFileSize(param) {
      ShowLoading();
      console.log(param);
      const file = this.$refs['' + param].files[0];

      if (file != undefined) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = async () => {
          const fileSize = file.size;

          this.currentRecord[param] = await this.compressImage(this.$refs[param].files[0]);

          if (fileSize > 1000000) {
            AlertPopup('error', '', 'File Melebihi 1 MB, silahkan compress gambar agar dibawah 1 MB', 0, true);
            this.$refs['' + param].value = null;
          }
        };
      }
      CloseLoading();
    },

    updatePenghasilanAyah(value) {
      // Remove non-digit characters and convert to a number
      const unformattedValue = parseFloat(value.replace(/[^0-9]/g, ""));
      // Check if the value is a valid number
      if (!isNaN(unformattedValue)) {
        // Update the currentRecord object with the unformatted value
        this.currentRecord.penghasilan_ayah = unformattedValue;
      }
    },
    updatePenghasilanIbu(value) {
      // Remove non-digit characters and convert to a number
      const unformattedValue = parseFloat(value.replace(/[^0-9]/g, ""));
      // Check if the value is a valid number
      if (!isNaN(unformattedValue)) {
        // Update the currentRecord object with the unformatted value
        this.currentRecord.penghasilan_ibu = unformattedValue;
      }
    },
    validasi_ktp_nisn(tipe, value) {

      const formData = new FormData();
      formData.append("url", "validasi_ktp_nisn");
      formData.append(tipe, value);
      formData.append("slug", "ustb");
      if (process.env.NODE_ENV === "development") {
        this.api = base_url + "validasi_ktp_nisn";
      } else {
        this.api = base_url + "validasi_ktp_nisn";
      }

      // Add a new record to the API
      axios
        .post(this.api, formData)
        .then((response) => {
          if (response.data.status == true) {
            // AlertPopup("error", "", response.data.message, 1500, false);

          }
          if (response.data.status == false) {
            this.currentRecord[tipe] = '';
            Swal.fire({
              title: '',
              text: response.data.message,
              showConfirmButton: true,
              allowEnterKey: false,
              allowOutsideClick: false,
              allowEscapeKey: false,
              icon: "error",
            }).then((result) => {
              if (result.isConfirmed) {
                //asdas
              }
            });
            // AlertPopup("error", "", response.data.message, 1500, false);

          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);

        });


    },
  },

  computed: {
    formattedPenghasilanAyah() {
      let angka = this.currentRecord.penghasilan_ayah || 0;
      angka = "Rp " + angka.toLocaleString("id-ID");
      angka = angka + ',-'
      return angka;

    },
    formattedPenghasilanIbu() {
      let angka = this.currentRecord.penghasilan_ibu || 0;
      angka = "Rp " + angka.toLocaleString("id-ID");
      angka = angka + ',-'
      return angka;
    },

  },

  mounted() {
    ShowLoading();
    //default value form
    this.currentRecord.penghasilan_ayah = 0;
    this.currentRecord.penghasilan_ibu = 0;
    this.currentRecord.anak_ke = 1;
    this.currentRecord.jumlah_bersaudara = 1;

    this.currentRecord.tanggal_lahir = DateNow();
    const dateNow = new Date();
    // subtract 15 years from the current year
    const year15 = new Date(dateNow.getFullYear() - 17, dateNow.getMonth(), dateNow.getDate());
    const year45 = new Date(dateNow.getFullYear() - 45, dateNow.getMonth(), dateNow.getDate());
    // set tanggal_sekarang to the updated date
    this.tanggal_sekarang = year15.toISOString().slice(0, 10);
    this.tanggal_max_lahir = year45.toISOString().slice(0, 10);
    this.tanggal_hari_ini = DateNow();
    this.currentRecord.tanggal_lahir = this.tanggal_sekarang;
    this.page_number = 1;
    this.indexData();
    this.provinsi_index();
    this.tahun_ajaran();
    CloseLoading();
    const formData = new FormData();
    formData.append("slug", "ustb");
    formData.append("url", "form_prodi");

    if (process.env.NODE_ENV === "development") {
      this.api = base_url + "form_prodi";
    } else {
      this.api = base_url + "form_prodi";
    }

    axios
      .post(this.api, formData)
      .then((response) => {
        this.prodiList = response.data.data;
      })
      .catch((error) => {
        console.log(error);
      });

    const userAgent = navigator.userAgent.toLowerCase();
    this.isMobile = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);

    this.SettingCaptchaView();

    this.$nextTick(() => {
      const dropdownToggle = document.querySelectorAll('.vs__dropdown-toggle');
      const searchInput = document.querySelectorAll('.vs__search');
      const focusedSearchInput = document.querySelectorAll('.vs__search:focus');
      const selectedOption = document.querySelectorAll('.vs__selected');

      dropdownToggle.forEach((toggle) => {
        toggle.style.border = 'none';
      });

      searchInput.forEach((input) => {
        input.style.fontSize = 'inherit';
        input.style.padding = '0';
      });

      focusedSearchInput.forEach((input) => {
        input.style.fontSize = 'inherit';
        input.style.padding = '0';
      });

      selectedOption.forEach((option) => {
        option.style.margin = '4px 0 0';
        option.style.padding = '0';
      });


    });

    this.tanggal_acuan_hari_ini = DateNow();

    window.addEventListener("contextmenu", (event) => {
      event.preventDefault();
    });

  },



};
</script>

<style scoped>
.custom-select .vs__dropdown-toggle,
.custom-select .vs__open-indicator {
  border: none;
}

a {
  text-decoration: none;
}
</style>
